
import React, { useState } from "react";
import {
    Row,
    Label,
    Button,
} from "reactstrap"
import './StorePasses.css'
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux";
import { addStorePassThunk } from "../../features/StorePass/storePassThunk";
import { useHistory } from "react-router-dom";

const AddStorePasses = () => {
    document.title = "Add New Store Pass"
    const dispatch = useDispatch();
    let history = useHistory();

    const [data, setData] = useState({
        // status: "",
        marketPlaceCutOff:'',
        auctionCutOff: '',
        listingLimit: '',
        liveStreamEnabled: '',
        subscriptionCutOff: '',
    })

    const stepAddSchema = Yup.object().shape({
        marketPlaceCutOff:Yup.number().required('This field is required'),
        auctionCutOff:Yup.number().required('This field is required'),
        listingLimit: Yup.number().required('This field is required'),
        liveStreamEnabled: Yup.boolean().required('This field is required'),
        subscriptionCutOff: Yup.number().required('This field is required'),
        // status: Yup.string().required('Status is required'),

    })

    const handleAddStorePass = async (values) => {
        console.log('data : ' , data)
        dispatch(addStorePassThunk(data))
            .unwrap()
            .then((res) => {
                console.log('res ponse : ' ,res)
                if (res.status) {
                    history.push('/store-passes');
                }
            }).catch((err) => {
                console.log(err.message);
            });

    }

    return (<>
        <Row>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Add New StorePass</h5>
                    <hr />
                    <Formik
                        initialValues={{
                            // status: "",
                            marketPlaceCutOff:'',
                            auctionCutOff: '',
                            listingLimit: '',
                            liveStreamEnabled: '',
                            subscriptionCutOff: '',
                        }}
                        validationSchema={stepAddSchema}
                        onSubmit={(values, actions) => {
                            handleAddStorePass(values)
                        }}
                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Form
                            >
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                       MarketPlace Cut-Off to PlatForm (in %)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="marketPlaceCutOff"
                                        name="marketPlaceCutOff"
                                        onChange={e => {
                                            setData({ ...data, marketPlaceCutOff: e.target.value })
                                            setFieldValue('marketPlaceCutOff', e.target.value)
                                        }}
                                    />
                                    {errors.marketPlaceCutOff && touched.marketPlaceCutOff ? (
                                        <div className="text-danger input-error">
                                            {errors.marketPlaceCutOff}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Auction Cut-Off to Platform (in %)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="auctionCutOff"
                                        name="auctionCutOff"
                                        onChange={e => {
                                            setData({ ...data, auctionCutOff: e.target.value })
                                            setFieldValue('auctionCutOff', e.target.value)
                                        }}
                                    />
                                    {errors.auctionCutOff && touched.auctionCutOff ? (
                                        <div className="text-danger input-error">
                                            {errors.auctionCutOff}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Product Listing Limit 
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="listingLimit"
                                        name="listingLimit"
                                        onChange={e => {
                                            setData({ ...data, listingLimit: e.target.value })
                                            setFieldValue('listingLimit', e.target.value)
                                        }}
                                    />
                                    {errors.listingLimit && touched.listingLimit ? (
                                        <div className="text-danger input-error">
                                            {errors.listingLimit}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Subscription Plan's Cut-Off to platform  (in %)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="subscriptionCutOff"
                                        name="subscriptionCutOff"
                                        onChange={e => {
                                            setData({ ...data, subscriptionCutOff: e.target.value })
                                            setFieldValue('subscriptionCutOff', e.target.value)
                                        }}
                                    />
                                    {errors.subscriptionCutOff && touched.subscriptionCutOff ? (
                                        <div className="text-danger input-error">
                                            {errors.subscriptionCutOff}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="Type">Add Live Stream Access ?  </Label>
                                    <Field name="liveStreamEnabled" className="form-control"
                                        onChange={e => {
                                            setData({ ...data, liveStreamEnabled: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="liveStreamEnabled" className="form-control input-color " name="liveStreamEnabled1" onChange={e => {
                                                    setData({ ...data, liveStreamEnabled: e.target.value })
                                                    setFieldValue('liveStreamEnabled', e.target.value)
                                                }}>
                                                    <option value="">Choose</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                                {form.touched.liveStreamEnabled &&
                                                    form.errors.liveStreamEnabled ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.liveStreamEnabled}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>


                                </div>

                                {/* <div className="mb-3">
                                    <Label htmlFor="Type">Status</Label>
                                    <Field name="status" className="form-control"
                                        onChange={e => {
                                            setData({ ...data, status: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="status" className="form-control input-color " name="status1" onChange={e => {
                                                    setData({ ...data, status: e.target.value })
                                                    setFieldValue('status', e.target.value)
                                                }}>
                                                    <option value="">Choose</option>
                                                    <option value="1">Active</option>
                                                    <option value="2">In-Active</option>
                                                </select>
                                                {form.touched.status &&
                                                    form.errors.status ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>


                                </div> */}

                                <Button type="submit" color="primary" >
                                    Create Pass
                                </Button>

                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

        </Row>
    </>);
}

export default AddStorePasses;