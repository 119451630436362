
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Breadcrumbs from "../../common/BreadCrums";
import { getTrendingProfilesThunk } from "../../features/OtherApi/ApiThunk";
import NoImgFound from "./../../assets/No-image-found.jpg"
const Profiles = () => {
    const dispatch = useDispatch();
    document.title = "Trending Profiles"
    const [profiles, setProfiles] = useState([]);


    useEffect(() => {
        dispatch(getTrendingProfilesThunk()).unwrap()
            .then((res) => {
                setProfiles(res);
            })
    }, [])

    return (
        <>

            <Breadcrumbs title="Trending Profiles" />

            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3">
                {profiles?.Collection?.length ? profiles?.Collection.map((item, index) => {
                    return (
                        <>
                            <div className="col">
                                <div className="card ">
                                    <div className="card-body d-flex justify-content-around align-items-center">
                                        <div>
                                            <img src={item.image ? `${process.env.REACT_APP_ASSET_URL}/${item.image}` : NoImgFound} className="card-img-top m-auto" alt="..." style={{ 'maxWidth': '120px', 'height': 'auto' }} />
                                        </div>
                                        <div>
                                            <h5 className="card-title text-uppercase">{item.userName} </h5>
                                            <p className="card-title d-flex">({item.subscribedBy ?? 0}) Subscriber <box-icon name='trending-up'></box-icon></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }) : ''}

            </div>
        </>
    );
}

export default Profiles;