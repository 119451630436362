
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import NoImgFound from "./../../assets/No-image-found.jpg"
import './Users.css'
import Breadcrumbs from "../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { getUserThunk } from "../../features/User/userThunk";
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";

const Users = () => {
    document.title = "Users Listing"

    const dispatch = useDispatch();
    const users = useSelector(state => state.userReducer.users);
    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        'order': '',
        "limit": 10,
        "current_page": 1
    });
    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
        });
    };

    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'order': '',
            "limit": 10,
            "current_page": 1
        })
    }

    useEffect(() => {
        dispatch(getUserThunk(filterData))
    }, [filterData])


    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                if ((users?.Pagination?.current_page - 1) != 0) {

                    return ((users?.Pagination?.current_page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            actualKey: 'name',
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            actualKey: 'email',
        },
        {
            name: 'Image',
            selector: (row) => {
                return <img className="tableImage" src={row.image != '' ? `${process.env.REACT_APP_ASSET_URL}${row.image}` : NoImgFound} />
            }
        },
        {
            name: 'Followers ',
            selector: row => row.followers?.length,
            sortable: true,
            actualKey: 'followers',
        },
        {
            name: 'Following ',
            selector: row => row.following?.length,
            sortable: true,
            actualKey: 'following',
        },

        {
            name: 'IsVerified ',
            selector: (row) => {
                if (row.isEmailVerified) {
                    return <box-icon type='solid' name='user-check'></box-icon>
                }
                return <box-icon name='user-x' type='solid' ></box-icon>
            },
            sortable: true,
            actualKey: 'following',
        },
        {
            name: "Actions",
            width: "200px",
            selector: row => (
                <div className="d-flex">

                    <Link to={`users/${row._id}/view`} className="btn btn-sm mx-1 btn-outline-primary" >
                        View
                    </Link>
                    <Link to="#" className="btn btn-sm  btn-outline-danger mx-1" onClick={() => {
                        alert(row._id)
                        // deleteUser(row._id);
                    }}>
                        Delete
                    </Link>
                </div>
            ),
        },
    ];



    return (<>
        <Breadcrumbs title="Users Listing" />
        <Row>
            <Col xl={12}>
                <div className="mb-2">
                    <Link to={'/users/add'} className="btn btn-primary w-md">Add +</Link>
                </div>
                <CCardGroup className="mb-2">
                    <CCard>
                        <CCardBody>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="w-100">
                                        <label>Search :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            value={filterData?.search}
                                            onChange={e => {
                                                handleFilterState('search', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1 justify-content-right">
                                    <div className="mt-3 justify-content-right">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            id="search"
                                            value="Reset"
                                            onClick={() => ResetFilters()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCardGroup>

                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={users?.Collection}
                                pagination
                                paginationServer
                                paginationTotalRows={users?.Pgaination?.total}
                                paginationDefaultPage={filterData?.current_page}
                                rowsPerPageOptions={[10, 20, 30]}
                                paginationPerPage={filterData.limit}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                paginationComponentOptions={{ selectAllRowsItem: true, }}
                                onChangePage={(page, totalRows) => {
                                    handleFilterState('current_page', page)
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>
    </>);
}

export default Users;