
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import './Brands.css'
import Breadcrumbs from "../../common/BreadCrums";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import { getBrandProductsThunk } from "../../features/Brand/brandThunk";
import { Link, useParams } from "react-router-dom";
import NoImgFound from "./../../assets/No-image-found.jpg"
const BrandProducts = () => {
    const pageRef = useRef(1)
    document.title = "Brand Products Listing"
    const [modal, setModal] = useState(false);
    const [singleProduct, setSingleProduct] = useState(null);
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();
    let params = useParams();
    const brandProducts = useSelector(state => state.brandReducer.brandProducts);

    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        "limit": 10,
    });

    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            "limit": 10,
            "brandId": '',
        })
    }

    useEffect(() => {
        dispatch(getBrandProductsThunk({ ...filterData, id: params?.id }))
    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop ===
            document.documentElement.scrollHeight
        ) {
            dispatch(getBrandProductsThunk({ ...filterData, page: pageRef.current + 1, id: params?.id })).unwrap()
                .then((res) => {
                    pageRef.current += 1
                }).catch(() => {

                })
        }
    };


    return (<>
        <Breadcrumbs title="Product Listing" />
        <Row>
            <Col xl={12}>
                <CCardGroup className="mb-2">
                    <CCard>
                        <CCardBody>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="w-100">
                                        <label>Search :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            value={filterData?.search}
                                            onChange={e => {
                                                handleFilterState('search', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1 justify-content-right">
                                    <div className="mt-3 justify-content-right">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            id="search"
                                            value="Reset"
                                            onClick={() => ResetFilters()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCardGroup>

                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-4">
                    {brandProducts?.Collection?.length ? brandProducts?.Collection.map((item, index) => {
                        return (
                            <><div className="col" key={index}>
                                <div className="card border-primary border-bottom border-3 border-0">
                                    <div className="p-4 text-center" style={{ 'height': '200px' }}>
                                        <img className="w-75 m-auto" src={process.env.REACT_APP_ASSET_URL + item?.variants[0]?.images[0]}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null;
                                                currentTarget.src = NoImgFound;
                                            }}
                                        />
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">{item.title}</p>
                                        <hr />
                                        <div className=" text-center gap-2">
                                            <Link to={`/users/${item?.seller?._id}/view`} className="btn btn-inverse-primary"><i className="bx bx-star"></i>view Seller</Link>
                                        </div>
                                    </div>
                                </div>
                            </div></>
                        )
                    }) : ''}
                </div>

            </Col>

        </Row>
    </>);
}

export default BrandProducts;