
import { Redirect } from 'react-router-dom';
import AuthLogin from '../Auth/AuthLogin'
import AuthSignUp from '../Auth/AuthSignUp';
import Dashboard from '../components/dashboard/dashboard';
import Categories from '../components/categories/Categories';
import SubCategories from '../components/subCategories/SubCategories';
import Users from '../components/users/Users';
import ListingAvailable from '../components/sellerStatistics/ListingAvailable';
import Brands from '../components/brands/Brands';
import AddBrands from '../components/brands/AddBrands';
import EditBrands from '../components/brands/EditBrands';
import HashTags from '../components/trending/HashTags';
import Profiles from '../components/trending/Profiles';
import BrandsListing from '../components/trending/BrandsListing';
import NotFound from '../components/NotFound';
import UserView from '../components/users/UserView';
import StorePasses from '../components/storePasses/StorePasses';
import AddStorePasses from '../components/storePasses/AddStorePasses';
import EditStorePasses from '../components/storePasses/EditStorePasses';
import Logout from '../Auth/logout';
import Products from '../components/products/Products';
import InAppSubscriptions from '../components/inAppSubscriptionPlans/InAppSubscriptions';
import AddInAppSubscriptions from '../components/inAppSubscriptionPlans/AddInAppSubscriptions';
import EditInAppSubscriptions from '../components/inAppSubscriptionPlans/EditInAppSubscriptions';
import ReferralPlan from '../components/referralPlans/EditStorePasses';
import BrandProducts from '../components/brands/BrandProducts';
import Sellers from '../components/featured/seller/Sellers';
import FeaturedProducts from '../components/featured/product/Products';
import Orders from '../components/orders/Orders';
import ViewOrder from '../components/orders/ViewOrder';

const normalRoutes = [
    { path: '/login', component: AuthLogin },
    { path: '/signup', component: AuthSignUp },
    { path: '/logout', component: Logout },
    { path: '/', component: () => <Redirect to="/login" /> },
    { path: '*', component: NotFound },
];

const authProtectedRoutes = [
    { path: '/dashboard', component: Dashboard },
    { path: '/categories', component: Categories },
    { path: '/users', component: Users },
    { path: '/users/:id/view', component: UserView },

    { path: '/brands', component: Brands },
    { path: '/brands/add', component: AddBrands },
    { path: '/brands/:id/edit', component: EditBrands },

    { path: '/products', component: Products },
    { path: '/orders', component: Orders },
    { path: '/orders/:id', component: ViewOrder },

    { path: '/store-passes', component: StorePasses },
    { path: '/store-passes/add', component: AddStorePasses },
    { path: '/store-passes/:id/edit', component: EditStorePasses },


    { path: '/in-app-subscriptions', component: InAppSubscriptions },
    { path: '/in-app-subscriptions/add', component: AddInAppSubscriptions },
    { path: '/in-app-subscriptions/:id/edit', component: EditInAppSubscriptions },


    { path: '/subcategories/:id', component: SubCategories },
    { path: '/trending/hashTags', component: HashTags },
    { path: '/trending/profiles', component: Profiles },
    { path: '/trending/brandsListing', component: BrandsListing },
    { path: '/trending/brands/:id/products', component: BrandProducts },

    { path: '/featured-sellers', component: Sellers },
    { path: '/curated-products', component: FeaturedProducts },


    { path: '/settings/referralProgram', component: ReferralPlan },
    
    { path: '/listing-available', component: ListingAvailable },


];

export { normalRoutes, authProtectedRoutes }