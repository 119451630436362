
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { axiosApi } from '../../helper/api_helper';
import * as url from "../../helper/url_helper";
import { loginAuth } from './authSlice';

// Create an async thunk for making API requests
export const loginUser = createAsyncThunk('auth/login', async (data, thunkApi) => {
  try{
    // thunkApi.dispatch()
    const response = await axiosApi.post(url.AUTH_LOGIN,data)  //AuthLogin(data);
    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    localStorage.setItem('authToken', response.data.accessToken);
    thunkApi.dispatch(loginAuth(response.data))
   return thunkApi.fulfillWithValue(response.data)
  
  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});


// Create an async thunk for making API requests
export const signupUser = createAsyncThunk('auth/signup', async (data, thunkApi) => {
  try{
    // thunkApi.dispatch()
    const response = await axiosApi.post(url.AUTH_SIGNUP,data)  //AuthLogin(data);
    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    console.log(response);
    return thunkApi.fulfillWithValue(response.data)
  
  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

