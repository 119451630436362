import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"
// import Spinner from "loader";
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import cloud from "../../../assets/images/cloud-file-download.svg"
import Dropzone from "react-dropzone"
import { fireToast } from "../../../common/toast"
import { getSubCategoryByIdThunk, updateSubCategoryByIdThunk } from "../../../features/SubCategories/subCategoryThunk"


const SubCategoryEdit = props => {
  const uploadRef = useRef(null)
  const dispatch = useDispatch();
  const { isOpen, toggle, editId } = props
  const [data, setData] = useState({})

  const [image, setImage] = useState({
    blob: null,
    src: "",
  })

  const stepAddSchema = Yup.object().shape({
    image: Yup.mixed().required('File is required'),
  })

  const handleAddSplashScreen = async (values) => {
    console.log(data)

    dispatch(updateSubCategoryByIdThunk(data)).unwrap()
    .then((res)=>{
      toggle();
    }).catch(()=>{

    })
  }

  useEffect(() => {
    console.log("+++++++++++");
    if(editId){
    dispatch(getSubCategoryByIdThunk(editId)).unwrap()
      .then((res) => {
        setData({ name: res.name, image: res.image, _id: res._id })
        setImage({src: `${process.env.REACT_APP_ASSET_URL}${res.image}`})
      }).catch((err) => {
      });
    }
  }, [editId]);


  useEffect(() => {
console.log('data changed' , data)
  }, [data]);

  const handleImageChange = files => {
    const extn = ["image/jpg", "image/png", "image/jpeg"]
    const [file] = files
    setData({ ...data, file })

    if (file && extn.includes(file.type)) {

      if (file.size <= 5242880) {
        setImage({ blob: file, src: window.URL.createObjectURL(file) })
      } else {
        fireToast("error", "image too large")
      }
    } else {
      fireToast(
        "error",
        "Please select a valid image file(only jpg, png and jpeg images are allowed)"
      )
    }
  }


  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal bs-example-modal" role="dialog"></div>
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Edit </ModalHeader>
        <Formik
          initialValues={{
            name: data?.name,
          }}
          validationSchema={stepAddSchema}
          onSubmit={(values, actions) => {
            handleAddSplashScreen(values)
          }}
        >
          {({ values, setValues, setFieldValue, errors, touched }) => (
            <Form

              onSubmit={(e) => {
                e.preventDefault();
                handleAddSplashScreen();
                return false;
              }}
            >
              <ModalBody>
                <div className="mb-3">
                  <Label htmlFor="formrow-firstname-Input">
                    Category Name
                  </Label>
                  <Field
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={data.name}
                    onChange={e => {
                      setData({ ...data, name: e.target.value })
                      setFieldValue('name', e.target.value)
                    }}
                  />
                  {errors.name && touched.name ? (
                    <div className="text-danger input-error">
                      {errors.name}
                    </div>
                  ) : null}
                </div>
                <div >
                  <Label htmlFor="formrow-firstname-Input">
                    Image
                  </Label>
                  <div className="text-center">

                    <div className="text-center">
                      <div className="mb-3 dragdrop-container">
                        <input
                          ref={uploadRef}
                          id="upload"
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={e =>
                            handleImageChange(e.target.files)
                          }
                        />
                        {data.image ? (
                          <div className="commonImgs">
                            <img
                              className="every-img"
                              src={image.src ? image.src : cloud}
                              alt=""
                              onClick={() => {
                                uploadRef.current.click()
                              }}
                            />
                          </div>
                        ) : (
                          <div className="drag-n-drop-container">
                            <div>
                              <Dropzone
                                accept="image/*"
                                multiple={false}
                                onDrop={acceptedFiles => {
                                  handleImageChange(acceptedFiles)
                                }}
                              >
                                {({
                                  getRootProps,
                                  getInputProps,
                                  isDragActive,
                                }) => (
                                  <section>
                                    <div
                                      className="drop-area"
                                      {...getRootProps()}
                                    >
                                      <img
                                        width={60}
                                        src={cloud}
                                        alt=""
                                      />
                                      <input
                                        {...getInputProps()}
                                        accept="image/*"
                                        multiple={false}
                                      />

                                      {isDragActive ? (
                                        <div>
                                          Drop your image file here
                                        </div>
                                      ) : (
                                        <p>
                                          Drag n drop image file here,
                                          or click to select <br />
                                          <small className="text-center">
                                            <strong>
                                              Supported files:
                                            </strong>{" "}
                                            jpeg, jpg, png. | Will be
                                            resized to: 1920x1080 px.
                                          </small>
                                        </p>
                                      )}
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="fw-bold">
                      Note:
                      <span className="text-danger mx-2 text-align-left">
                        Supported image formats are:&nbsp;jpg, png and
                        jpeg only
                      </span>
                    </p>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary" >
                  Save Changes
                </Button>
                <Button type="button" color="secondary" onClick={toggle}>
                  Close
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}

export default SubCategoryEdit