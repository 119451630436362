
import React, { useState } from "react";
import {
    Row,
    Label,
    Button,
} from "reactstrap"
import './InAppSubscriptions.css'
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { addInAppSubscriptionThunk, getInAppSubscriptionsByIdThunk, updateInAppSubscriptionsByIdThunk } from "../../features/InAppSubscriptions/inAppSubscriptionThunk";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

const EditInAppSubscriptions = () => {
    document.title = "Edit In-App Subscription"
    const dispatch = useDispatch();
    let history = useHistory();
    const params = useParams();

    const subscription = useSelector(state => state.inAppSubscriptionReducer.subscription)
    const [data, setData] = useState({
         // status: "",
         name:'',
         description:'',
         playStorePlanId:'',
         appStorePlanId:'',
         marketPlaceCutOff:'',
         price:'',
         auctionCutOff: '',
         listingLimit: '',
         liveStreamEnabled: '',
         subscriptionAccessEnabled:true,
         subscriptionCutOff: '',
    })

    const stepAddSchema = Yup.object().shape({
        name:Yup.string().required('This field is required'),
        description:Yup.string().required('This field is required'),
        appStorePlanId:Yup.string().required('This field is required'),
        playStorePlanId:Yup.string().required('This field is required'),
        marketPlaceCutOff:Yup.number().required('This field is required'),
        auctionCutOff:Yup.number().required('This field is required'),
        price:Yup.number().required('This field is required'),
        listingLimit: Yup.number().required('This field is required'),
        liveStreamEnabled: Yup.boolean().required('This field is required'),
        subscriptionAccessEnabled: Yup.boolean().required('This field is required'),
        
        // status: Yup.string().required('Status is required'),

    })

    const handleAddInAppSubscription = async (values) => {
        console.log('data : ' , data)
        dispatch(updateInAppSubscriptionsByIdThunk(data))
            .unwrap()
            .then((res) => {
                console.log('response : ' ,res)
                if (res.status) {
                    history.push('/in-app-subscriptions');
                }
            }).catch((err) => {
                console.log(err.message);
            });

    }

    useEffect(() => {
        dispatch(getInAppSubscriptionsByIdThunk(params?.id)).unwrap()
            .then((res) => {
                
            }).catch((error) => {
                toast.error(error.message /* error.message */, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    }, []);

    useEffect(()=>{
        console.log('subscription : ' , subscription)
        setData(subscription)
    },[subscription])



    return (<>
        <Row>
            <div className="card">
                <div className="card-body p-4">
                    <h5 className="card-title">Edit In App Subscription</h5>
                    <hr />
                    <Formik
                        initialValues={{
                            // status: "",
                            name:data?.name,
                            description:data?.description,
                            playStorePlanId:data?.playStorePlanId,
                            appStorePlanId:data?.appStorePlanId,
                            marketPlaceCutOff:data?.marketPlaceCutOff,
                            auctionCutOff: data?.auctionCutOff,
                            price:data?.price,
                            listingLimit: data?.listingLimit,
                            liveStreamEnabled: data?.liveStreamEnabled,
                            subscriptionAccessEnabled:data?.subscriptionAccessEnabled,
                            subscriptionCutOff: data?.subscriptionCutOff,
                        }}
                        enableReinitialize={true}
                        validationSchema={stepAddSchema}
                        onSubmit={(values, actions) => {
                            handleAddInAppSubscription(values)
                        }}
                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Form
                            >
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                       Name of Plan
                                    </Label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        onChange={e => {
                                            setData({ ...data, name: e.target.value })
                                            setFieldValue('name', e.target.value)
                                        }}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="text-danger input-error">
                                            {errors.name}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                       Description
                                    </Label>
                                    <Field
                                        type="textarea"
                                        className="form-control"
                                        id="description"
                                        name="description"
                                        onChange={e => {
                                            setData({ ...data, description: e.target.value })
                                            setFieldValue('description', e.target.value)
                                        }}
                                    />
                                    {errors.description && touched.description ? (
                                        <div className="text-danger input-error">
                                            {errors.description}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      PlayStore Plan Id
                                    </Label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="playStorePlanId"
                                        name="playStorePlanId"
                                        onChange={e => {
                                            setData({ ...data, playStorePlanId: e.target.value })
                                            setFieldValue('playStorePlanId', e.target.value)
                                        }}
                                    />
                                    {errors.playStorePlanId && touched.playStorePlanId ? (
                                        <div className="text-danger input-error">
                                            {errors.playStorePlanId}
                                        </div>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                      AppStore Plan Id
                                    </Label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="appStorePlanId"
                                        name="appStorePlanId"
                                        onChange={e => {
                                            setData({ ...data, appStorePlanId: e.target.value })
                                            setFieldValue('appStorePlanId', e.target.value)
                                        }}
                                    />
                                    {errors.appStorePlanId && touched.appStorePlanId ? (
                                        <div className="text-danger input-error">
                                            {errors.appStorePlanId}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                       MarketPlace Cut-Off to PlatForm (in %)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="marketPlaceCutOff"
                                        name="marketPlaceCutOff"
                                        onChange={e => {
                                            setData({ ...data, marketPlaceCutOff: e.target.value })
                                            setFieldValue('marketPlaceCutOff', e.target.value)
                                        }}
                                    />
                                    {errors.marketPlaceCutOff && touched.marketPlaceCutOff ? (
                                        <div className="text-danger input-error">
                                            {errors.marketPlaceCutOff}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Auction Cut-Off to Platform (in %)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="auctionCutOff"
                                        name="auctionCutOff"
                                        onChange={e => {
                                            setData({ ...data, auctionCutOff: e.target.value })
                                            setFieldValue('auctionCutOff', e.target.value)
                                        }}
                                    />
                                    {errors.auctionCutOff && touched.auctionCutOff ? (
                                        <div className="text-danger input-error">
                                            {errors.auctionCutOff}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                       Price
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="price"
                                        name="price"
                                        onChange={e => {
                                            setData({ ...data, price: e.target.value })
                                            setFieldValue('price', e.target.value)
                                        }}
                                    />
                                    {errors.price && touched.price ? (
                                        <div className="text-danger input-error">
                                            {errors.price}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Product Listing Limit 
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="listingLimit"
                                        name="listingLimit"
                                        onChange={e => {
                                            setData({ ...data, listingLimit: e.target.value })
                                            setFieldValue('listingLimit', e.target.value)
                                        }}
                                    />
                                    {errors.listingLimit && touched.listingLimit ? (
                                        <div className="text-danger input-error">
                                            {errors.listingLimit}
                                        </div>
                                    ) : null}
                                </div>
                                
                                <div className="mb-3">
                                    <Label htmlFor="Type">Add Live Stream Access ?  </Label>
                                    <Field name="liveStreamEnabled" className="form-control"
                                        onChange={e => {
                                            setData({ ...data, liveStreamEnabled: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="liveStreamEnabled" className="form-control input-color " name="liveStreamEnabled1" onChange={e => {
                                                    setData({ ...data, liveStreamEnabled: e.target.value })
                                                    setFieldValue('liveStreamEnabled', e.target.value)
                                                }}
                                                value={data.liveStreamEnabled}>>
                                                    <option value="">Choose</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                                {form.touched.liveStreamEnabled &&
                                                    form.errors.liveStreamEnabled ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.liveStreamEnabled}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>


                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="Type">Subscription Plan Creation Access ?  </Label>
                                    <Field name="subscriptionAccessEnabled" className="form-control"
                                        onChange={e => {
                                            setData({ ...data, subscriptionAccessEnabled: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="subscriptionAccessEnabled" className="form-control input-color " name="subscriptionAccessEnabled1" onChange={e => {
                                                    setData({ ...data, subscriptionAccessEnabled: e.target.value })
                                                    setFieldValue('subscriptionAccessEnabled', e.target.value)
                                                }}
                                                value={data.subscriptionAccessEnabled}>>
                                                    <option value="">Choose</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                                {form.touched.subscriptionAccessEnabled &&
                                                    form.errors.subscriptionAccessEnabled ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.subscriptionAccessEnabled}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>


                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Subscription Plan's Cut-Off % <small className="text-danger">(Required only if Subscription Plan Creation Access is granted.)</small>
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="subscriptionCutOff"
                                        name="subscriptionCutOff"
                                        onChange={e => {
                                            setData({ ...data, subscriptionCutOff: e.target.value })
                                            setFieldValue('subscriptionCutOff', e.target.value)
                                        }}
                                    />
                                    {errors.subscriptionCutOff && touched.subscriptionCutOff ? (
                                        <div className="text-danger input-error">
                                            {errors.subscriptionCutOff}
                                        </div>
                                    ) : null}
                                </div>
                                {/* <div className="mb-3">
                                    <Label htmlFor="Type">Status</Label>
                                    <Field name="status" className="form-control"
                                        onChange={e => {
                                            setData({ ...data, status: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="status" className="form-control input-color " name="status1" onChange={e => {
                                                    setData({ ...data, status: e.target.value })
                                                    setFieldValue('status', e.target.value)
                                                }}>
                                                    <option value="">Choose</option>
                                                    <option value="1">Active</option>
                                                    <option value="2">In-Active</option>
                                                </select>
                                                {form.touched.status &&
                                                    form.errors.status ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>


                                </div> */}

                                <Button type="submit" color="primary" >
                                    Update
                                </Button>

                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

        </Row>
    </>);
}

export default EditInAppSubscriptions;