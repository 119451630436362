
import React, { useEffect, useRef, useState } from "react";
import {
    Row,
    Label,
    Button,
} from "reactstrap"
import { Field, Form, Formik } from "formik"

import Dropzone from "react-dropzone";
import * as Yup from "yup"
import { fireToast } from "../../../common/toast";
import cloud from "../../../assets/images/cloud-file-download.svg"
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateUserByIdThunk } from "../../../features/User/userThunk";

const UserAccountSetting = (props) => {
    let { userData } = props;
    document.title = "Edit Brand"
    const dispatch = useDispatch();
    const [data, setData] = useState(null)
    const uploadRef = useRef(null)
    const [image, setImage] = useState({ blob: null, src: "" })

    const stepAddSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        status: Yup.string().required('Status is required'),
    })

    const handleUpdateBrand = async () => {
        alert('submitted');
        dispatch(updateUserByIdThunk(data))
            .unwrap()
            .then((res) => {

            }).catch((err) => {
                console.log(err.message);
            });

    }



    const handleImageChange = files => {
        const extn = ["image/jpg", "image/png", "image/jpeg"]
        const [file] = files
        setData({ ...data, file })

        if (file && extn.includes(file.type)) {

            if (file.size <= 5242880) {
                setImage({ blob: file, src: window.URL.createObjectURL(file) })
            } else {
                fireToast("error", "image too large")
            }
        } else {
            fireToast(
                "error",
                "Please select a valid image file(only jpg, png and jpeg images are allowed)"
            )
        }
    }

    useEffect(() => {
        setData(userData)
        setImage({ src: userData?.image })
    }, []);


    return (
        <div className="col-md-12">
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Account Setting</h5>
                    <hr />
                    <Formik
                        initialValues={
                            {
                                data
                            }
                        }
                        enableReinitialize={true}
                        validationSchema={stepAddSchema}
                        onSubmit={(values, actions) => {
                            handleUpdateBrand()
                            return false;
                        }}
                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Form className="row"
                            >
                                <div className="mb-3 col-sm-12 col-md-6">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Name
                                    </Label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        onChange={e => {
                                            setData({ ...data, name: e.target.value })
                                            setFieldValue('name', e.target.value)
                                        }}
                                        value={data?.name}

                                    />
                                    {errors.name && touched.name ? (
                                        <div className="text-danger input-error">
                                            {errors.name}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3 col-sm-12 col-md-6">
                                    <Label htmlFor="formrow-firstname-Input">
                                       Email
                                    </Label>
                                    <Field
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        onChange={e => {
                                            setData({ ...data, email: e.target.value })
                                            setFieldValue('email', e.target.value)
                                        }}
                                        value={data?.email}

                                    />
                                    {errors.email && touched.email ? (
                                        <div className="text-danger input-error">
                                            {errors.email}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3 col-sm-12 col-md-6">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Update Password
                                    </Label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="password"
                                        onChange={e => {
                                            setData({ ...data, password: e.target.value })
                                            setFieldValue('password', e.target.value)
                                        }}

                                    />
                                    {errors.password && touched.password ? (
                                        <div className="text-danger input-error">
                                            {errors.password}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3 col-sm-12 col-md-6">
                                    <Label htmlFor="Type">Status</Label>
                                    <Field name="status" className="form-control"
                                        onChange={e => {
                                            setData({ ...data, status: e.target.value })
                                        }}
                                    >

                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="status" className="form-control input-color "
                                                    value={data?.status}
                                                    name="status1" onChange={e => {
                                                        setData({ ...data, status: e.target.value })
                                                        setFieldValue('status', e.target.value)
                                                    }}>
                                                    <option value="">Choose</option>
                                                    <option value="1">Active</option>
                                                    <option value="2">In-Active</option>
                                                </select>
                                                {form.touched.status &&
                                                    form.errors.status ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>


                                </div>

                                <div className="mb-3 col-sm-12 col-md-6 m-auto text-center">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Image
                                    </Label>
                                    <div className="text-center">

                                        <div className="text-center">
                                            <div className="mb-3 dragdrop-container">
                                                <input
                                                    ref={uploadRef}
                                                    id="upload"
                                                    hidden
                                                    name="image"
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={e =>
                                                        handleImageChange(e.target.files)
                                                    }
                                                />
                                                {image?.src ? (
                                                    <div className="commonImgs">
                                                        <img
                                                            className="every-img"
                                                            src={image?.src ? image.src : cloud}
                                                            alt=""
                                                            onClick={() => {
                                                                uploadRef.current.click()
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="drag-n-drop-container">
                                                        <div>
                                                            <Dropzone
                                                                accept="image/*"
                                                                multiple={false}
                                                                onDrop={acceptedFiles => {
                                                                    handleImageChange(acceptedFiles)
                                                                }}
                                                            >
                                                                {({
                                                                    getRootProps,
                                                                    getInputProps,
                                                                    isDragActive,
                                                                }) => (
                                                                    <section>
                                                                        <div
                                                                            className="drop-area"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <img
                                                                                width={60}
                                                                                src={cloud}
                                                                                alt=""
                                                                            />
                                                                            <input
                                                                                {...getInputProps()}
                                                                                accept="image/*"
                                                                                multiple={false}
                                                                            />

                                                                            {isDragActive ? (
                                                                                <div>
                                                                                    Drop your image file here
                                                                                </div>
                                                                            ) : (
                                                                                <p>
                                                                                    Drag n drop image file here,
                                                                                    or click to select <br />
                                                                                    <small className="text-center">
                                                                                        <strong>
                                                                                            Supported files:
                                                                                        </strong>{" "}
                                                                                        jpeg, jpg, png. | Will be
                                                                                        reized to: 1920x1080 px.
                                                                                    </small>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p className="fw-bold">
                                            Note:
                                            <span className="text-danger mx-2 text-align-left">
                                                Supported image formats are:&nbsp;jpg, png and
                                                jpeg only
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <Button type="submit" color="primary" >
                                    Save Changes
                                </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
}

export default UserAccountSetting;