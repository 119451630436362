import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    orders: [],
    order: {}
}


export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        getOrders: (state, action) => {

            return {
                ...state,
                orders: action.payload
            };
        },
        addOrders: (state, action) => {

            return {
                ...state,
                orders: {
                    ...state.orders,
                    Collection: [...state.orders.Collection, action.payload]
                },
            };
        },
        getOrderById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                order: action.payload,
            };
        },

        updateOrderById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                orders: {
                    ...state.orders,
                    Collection: state.orders.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        },

        deleteOrderById: (state, action) => {
            return {
                ...state,
                orders: {
                    ...state.orders,
                    Collection: state.orders.Collection.filter(item => item._id !== action.payload)
                },
            };
        }

    }
})


export const { getOrders, addOrders, getOrderById, updateOrderById, deleteOrderById } = orderSlice.actions
export default orderSlice.reducer