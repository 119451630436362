import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import { isloading } from "../Loader/loaderSlice";
import { addCategories, deleteCategoryById, getCategories, getCategoryById, updateCategoryById } from "./categorySlice";


export const getCategoryThunk = createAsyncThunk('categories', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(url.GET_CATEGORIES, data)  //AuthLogin(data);

    thunkApi.dispatch(getCategories(response.data))
    thunkApi.dispatch(isloading(false))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getCategoryByIdThunk = createAsyncThunk('categories/edit', async (id, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_CATEGORIES}/${id}`)  //AuthLogin(data);
    thunkApi.dispatch(getCategoryById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const updateCategoryByIdThunk = createAsyncThunk('categories/update', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    let image = data.image;
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }
    console.log('image ', image)

    const response = await axiosApi.post(`${url.UPDATE_CATEGORIES}/${data._id}/edit`, { name: data.name, image });  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(updateCategoryById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const deleteCategoryByIdThunk = createAsyncThunk('categories/delete', async (id, thunkApi) => {
  try {

    const response = await axiosApi.delete(`${url.DELETE_CATEGORY}/${id}`);  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(deleteCategoryById(id))
    return thunkApi.fulfillWithValue(id)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const addCategoryThunk = createAsyncThunk('categories/add', async ({ data }, thunkApi) => {
  try {
    let image = []
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }
    console.log('image ', image)

    const response = await axiosApi.post(url.ADD_CATEGORIES, { name: data.name, image });  //AuthLogin(data);
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(addCategories(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});