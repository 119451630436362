
import React, { useEffect,  useState } from "react";
import {
    Row,
    Label,
    Button,
} from "reactstrap"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getReferralProgramSettingThunk, updateReferralProgramSettingThunk } from "../../features/OtherApi/ApiThunk";

const ReferralPlan = () => {
    document.title = "Referral Program Setting"
    const dispatch = useDispatch();
    let history = useHistory();
    let params = useParams();
    const storePass = useSelector((state) => state.storePassReducer.storePass)
    const [data, setData] = useState({
        referralType: '',
        rewardType: '',
        rewardValue: '',
        expireInDays: '',
        status: '',
    })

    const stepAddSchema = Yup.object().shape({
        referralType: Yup.string().required('This field is required'),
        rewardType: Yup.string().required('This field is required'),
        rewardValue: Yup.number().required('This field is required'),
        expireInDays: Yup.number().required('This field is required'),
        status: Yup.number().required('This field is required'),
    })

    const handleUpdateBrand = async () => {
        dispatch(updateReferralProgramSettingThunk(data))
            .unwrap()
            .then((res) => {
                toast.success('success' /* error.message */, {
                    position: toast.POSITION.TOP_RIGHT
                  });
            }).catch((err) => {
                console.log(err.message);
            });

    }

    useEffect(() => {
        dispatch(getReferralProgramSettingThunk()).unwrap()
            .then((res) => {
                    setData(res)
            });

    }, []);
    
    return (<>
        <Row>
            <div className="card">
                <div className="card-body p-4">
                    <h5 className="card-title">Referral Program Setting</h5>
                    <hr />
                    <Formik
                        initialValues={
                            {
                                referralType: data.referralType,
                                rewardType: data.rewardType,
                                rewardValue: data.rewardValue,
                                expireInDays: data.expireInDays,
                                status: data.status,
                            }
                        }
                        enableReinitialize={true}
                        validationSchema={stepAddSchema}
                        onSubmit={(values, actions) => {
                            handleUpdateBrand()
                            return false;
                        }}
                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Form
                            >
                                <div className="mb-3">
                                    <Label htmlFor="referralType">Type of referral program </Label>
                                    <Field name="referralType" className="form-control"
                                        
                                        onChange={e => {
                                            setData({ ...data, referralType: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="referralType" className="form-control input-color " name="referralType1" onChange={e => {
                                                    setData({ ...data, referralType: e.target.value })
                                                    setFieldValue('referralType', e.target.value)
                                                }}
                                                value={data.referralType}>
                                                    <option value="">Choose</option>
                                                    <option value='cashCredit'>Cash Bonus </option>
                                                    <option value='discount'>Discount on first Item</option>
                                                    <option value='salesCuttOff'>percentage of the sales of referral</option>
                                                </select>
                                                {form.touched.referralType &&
                                                    form.errors.referralType ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.referralType}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="Type">Referal Bonus Type: </Label>
                                    <Field name="rewardType" className="form-control"
                                        
                                        onChange={e => {
                                            setData({ ...data, rewardType: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="rewardType" className="form-control input-color " name="rewardType1" onChange={e => {
                                                    setData({ ...data, rewardType: e.target.value })
                                                    setFieldValue('rewardType', e.target.value)
                                                }}
                                                value={data.rewardType}>
                                                    <option value="">Choose</option>
                                                    <option value='flat'>Flat </option>
                                                    <option value='percentage'>in Percentage</option>
                                                </select>
                                                {form.touched.rewardType &&
                                                    form.errors.rewardType ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.rewardType}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                       Reward Value <small></small>
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="rewardValue"
                                        name="rewardValue"
                                        onChange={e => {
                                            setData({ ...data, rewardValue: e.target.value })
                                            setFieldValue('rewardValue', e.target.value)
                                        }}
                                    />
                                    {errors.rewardValue && touched.rewardValue ? (
                                        <div className="text-danger input-error">
                                            {errors.rewardValue}
                                        </div>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                       Reward Expires in <small className="text-danger">(If Not used expires in ** days)</small>
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="expireInDays"
                                        name="expireInDays"
                                        onChange={e => {
                                            setData({ ...data, expireInDays: e.target.value })
                                            setFieldValue('expireInDays', e.target.value)
                                        }}
                                    />
                                    {errors.expireInDays && touched.expireInDays ? (
                                        <div className="text-danger input-error">
                                            {errors.expireInDays}
                                        </div>
                                    ) : null}
                                </div>

                                <div className="mb-3">
                                    <Label htmlFor="Type">Referral Program status </Label>
                                    <Field name="status" className="form-control"
                                        
                                        onChange={e => {
                                            setData({ ...data, status: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="status" className="form-control input-color " name="status1" onChange={e => {
                                                    setData({ ...data, status: e.target.value })
                                                    setFieldValue('status', e.target.value)
                                                }}
                                                value={data.status}>
                                                    <option value="">Choose</option>
                                                    <option value={1}>Active </option>
                                                    <option value={0}>in Active</option>
                                                </select>
                                                {form.touched.status &&
                                                    form.errors.status ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                
                                <Button type="submit" color="primary" >
                                    Update
                                </Button>

                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

        </Row>
    </>);
}

export default ReferralPlan;