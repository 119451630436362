
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  Col, Row } from "reactstrap";
import './InAppSubscriptions.css'
import Breadcrumbs from "../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import { deleteInAppSubscriptionsByIdThunk, getInAppSubscriptionsThunk } from "../../features/InAppSubscriptions/inAppSubscriptionThunk";
import { toast } from "react-toastify";
import { toastConfirm } from "../../common/toast";

const InAppSubscriptions = () => {
    document.title = "App Subscriptions"
    const dispatch = useDispatch();
    const subscriptions = useSelector(state => state.inAppSubscriptionReducer.subscriptions);
    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        "limit": 10,
        "current_page": 1
    });
    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
        });
    };
    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            "limit": 10,
            "current_page": 1
        })
    }
       

    useEffect(() => {
        dispatch(getInAppSubscriptionsThunk(filterData)).unwrap().then((res) =>{
            console.log(res.length)
        }) 
    }, [filterData])

    const deleteSubscription = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to delete this?")).fire();
        if (response.isConfirmed) {
            dispatch(deleteInAppSubscriptionsByIdThunk(id)).unwrap()
                .then(() => {

                })
                .catch((error) => {
                    toast.error(error.message /* error.message */, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    }

    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
               
                return index + 1
            },
            sortable: true,
            width: "60px"
        },
        {
            name: 'Plan Name',
            selector: row => row.name,
            sortable: false,
            actualKey: 'name',
        },
        {
            name: 'Product Listing Limit',
            selector: row => row.listingLimit,
            sortable: false,
            actualKey: 'listingLimit',
        },
        {
            name: 'Live Stream',
            selector: row => row.liveStreamEnabled ? 'Allowed' : 'Not-Allowed',
            sortable: false,
            actualKey: 'liveStreamEnabled',
        },
        {
            name: 'Subscription CutOff',
            selector: row => row.subscriptionCutOff,
            sortable: false,
            actualKey: 'subscriptionCutOff',
        },
        {
            name: 'Market Place CutOff',
            selector: row => row.marketPlaceCutOff,
            sortable: false,
            actualKey: 'marketPlaceCutOff',
        },
        {
            name: 'Auction CutOff',
            selector: row => row.auctionCutOff,
            sortable: false,
            actualKey: 'auctionCutOff',
        },
        {
            name: 'Active Users',
            selector: row => row.subscribers?.length ?? 0,
            sortable: false,
            actualKey: 'user',
        },
        {
            name: "Actions",
            width: "100px",
            selector: row => (
                <>
                    <Link to={`in-app-subscriptions/${row._id}/edit`} >
                        <box-icon type='solid' size='sm' color='#008cff' className="px-2" name='edit' title="Edit Store Pass"></box-icon>
                    </Link>
                    {/* <box-icon name='trash' type='solid' size='sm' color='#008cff' className="px-2 " 
                    style={{"cursor" :"pointer"}}
                        onClick={() => {
                            // alert(row._id)
                            deleteSubscription(row._id);
                        }}>

                    </box-icon> */}
                </>
            ),
        },
    ];



    return (<>
        <Breadcrumbs title="SubscriptionPlan Listing" />
        <Row>
            <Col xl={12}>
                <div className="mb-2">
                    {console.log('length :',subscriptions.length)}
                   {(subscriptions.length >=3 )  ? '' :   <Link to={'/in-app-subscriptions/add'} className="btn btn-primary w-md">Add +</Link> } 
                </div>
                <CCardGroup className="mb-2">
                    <CCard>
                        <CCardBody>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="w-100">
                                        <label>Search :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            value={filterData?.search}
                                            onChange={e => {
                                                handleFilterState('search', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1 justify-content-right">
                                    <div className="mt-3 justify-content-right">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            id="search"
                                            value="Reset"
                                            onClick={() => ResetFilters()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCardGroup>

                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={subscriptions}
                                pagination={false}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>
        
    </>);
}

export default InAppSubscriptions;