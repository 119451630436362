import axios from "axios";

//pass new generated access token here
// const token = accessToken;

//apply base url for axios
const API_URL = `${process.env.REACT_APP_API_URL}`;

export const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = '';

export function setTokenHeader(token) {
  axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  axiosApi.defaults.headers.common['Content-Type'] = 'multipart/form-data';
}

// Request interceptor
axiosApi.interceptors.request.use(function (config) {
  // console.log(config.url, "Request :", config)
  return config;
}, function (error) {
  // console.log(error.config.url, "Request Err :", error)
  return Promise.reject(error);
});
// Response interceptor
axiosApi.interceptors.response.use(function (response) {
  // console.log(response.status, response.config.url, "Response :", JSON.stringify(response.data))
  return response.data;
}, function (error) {
  // console.log(error?.response?.data?.statusCode, error?.config?.url, "Response Err :", error?.response?.data || error.message)
  return Promise.reject(error?.response?.data || { message: error.message, status: error?.response?.status });
});


