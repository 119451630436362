import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subCategories: [],
    subCategory: {}
}


export const subCategorySlice = createSlice({
    name: 'subCategory',
    initialState,
    reducers: {
        getSubCategories: (state, action) => {

            return {
                ...state,
                subCategories: action.payload
            };
        },
        addSubCategories: (state, action) => {

            return {
                ...state,
                subCategories: {
                    ...state.subCategories,
                    Collection: [...state.subCategories.Collection, action.payload]
                },
            };
        },
        getSubCategoryById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                category: action.payload,
            };
        },

        updateSubCategoryById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                subCategories: {
                    ...state.subCategories,
                    Collection: state.subCategories.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        },

        deleteCategoryById: (state, action) => {
            return {
                ...state,
                subCategories: {
                    ...state.subCategories,
                    Collection: state.subCategories.Collection.filter(item => item._id !== action.payload)
                },
            };
        }

    }
})


export const { getSubCategories, addSubCategories, getSubCategoryById, updateSubCategoryById, deleteSubCategoryById } = subCategorySlice.actions
export default subCategorySlice.reducer