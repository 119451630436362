import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import { addStorePasses, deleteStorePassById, getStorePasses, getStorePassById, updateStorePassById } from "./storePassSlice";


export const getStorePassThunk = createAsyncThunk('storePasses', async (filters, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.post(url.GET_STORE_PASSES, filters)  //AuthLogin(data);

    thunkApi.dispatch(getStorePasses(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getStorePassByIdThunk = createAsyncThunk('storePasses/edit', async (id, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_STORE_PASSES}/${id}`);
    thunkApi.dispatch(getStorePassById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const updateStorePassByIdThunk = createAsyncThunk('storePasses/update', async (data, thunkApi) => {
  try {

    const response = await axiosApi.post(`${url.UPDATE_STORE_PASSES}/${data._id}/edit`, {
      marketPlaceCutOff: data.marketPlaceCutOff,
      auctionCutOff: data.auctionCutOff,
      listingLimit: data.listingLimit,
      liveStreamEnabled: data.liveStreamEnabled,
      subscriptionCutOff: data.subscriptionCutOff,
    });  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(updateStorePassById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const deleteStorePassByIdThunk = createAsyncThunk('storePasses/delete', async (id, thunkApi) => {
  try {

    const response = await axiosApi.delete(`${url.DELETE_STORE_PASS}/${id}`);  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(deleteStorePassById(id))
    return thunkApi.fulfillWithValue(id)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const addStorePassThunk = createAsyncThunk('storePasses/add', async (data, thunkApi) => {
  try {

    const response = await axiosApi.post(url.ADD_STORE_PASSES, data);  //AuthLogin(data);
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(addStorePasses(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});