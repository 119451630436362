
import React, { useEffect, useState } from "react";
import {  useDispatch } from "react-redux";
import Breadcrumbs from "../../common/BreadCrums";
import { getTrendingHashTagsThunk } from "../../features/OtherApi/ApiThunk";

const HashTags = () => {
    const dispatch = useDispatch();
    document.title = "Trending HashTags"
    const [hashTags,setHashTags] = useState([]);


    useEffect(() => {
        dispatch(getTrendingHashTagsThunk()).unwrap()
            .then((res) => {
                setHashTags(res);
            })
    }, [])

    return (
        <>

            <Breadcrumbs title=" Trending HashTags On Stores" />

            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3">
                {hashTags?.Collection?.length ? hashTags?.Collection.map((item, index) => {
                    return (
                        <>
                            <div className="col">
                                <div className="card ">
                                    <div className="card-body d-flex justify-content-around align-items-center">
                                        <div>
                                            <p className="h1"><strong>{index + 1}</strong></p>
                                        </div>
                                        <div>
                                            <h5 className="card-title text-uppercase">#{item._id} </h5>
                                            <p className="card-title d-flex">({item.usedBy} mentions) <box-icon name='trending-up'></box-icon></p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }) : ''}

            </div>
        </>
    );
}

export default HashTags;