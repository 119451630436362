import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subscriptions: [],
    subscription: {}
}


export const inAppSubscriptionSlice = createSlice({
    name: 'inAppSubscription',
    initialState,
    reducers: {
        getInAppSubscriptions: (state, action) => {

            return {
                ...state,
                subscriptions: action.payload
            };
        },
        addInAppSubscriptions: (state, action) => {

            return {
                ...state,
                subscriptions: [...state.subscriptions, action.payload]
            };
        },
        getInAppSubscriptionsById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                subscription: action.payload,
            };
        },

        updateInAppSubscriptionsById: (state, action) => {
            return {
                ...state,
                subscriptions: state.subscriptions.map(item => item._id == action.payload._id ? action.payload : item)

            };
        },

        deleteInAppSubscriptionsById: (state, action) => {
            return {
                ...state,
                subscriptions: state.subscriptions.filter(item => item._id !== action.payload)
            };
        }

    }
})


export const { getInAppSubscriptions, addInAppSubscriptions, getInAppSubscriptionsById, updateInAppSubscriptionsById, deleteInAppSubscriptionsById } = inAppSubscriptionSlice.actions
export default inAppSubscriptionSlice.reducer