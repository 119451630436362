import React, { useEffect } from "react";

import { authProtectedRoutes, normalRoutes } from "./routes/index"
import { Switch, BrowserRouter as Router } from "react-router-dom";
import NonAuthLayout from "./layout/nonAuthLayout";
import AuthLayout from "./layout/AuthLayout";
import Authmiddleware from "./routes/AuthMiddleware";
import { setTokenHeader } from "./helper/api_helper";
import { useDispatch } from "react-redux";
import { loginAuth } from "./features/Auth/authSlice";
import { useSelector } from "react-redux";

function App() {
const authenticated = useSelector(state => state.authReducer.authenticated) 
  let dispatch = useDispatch();

  useEffect(() => {
    checkAuth();
  }, [])
  
  const checkAuth = () => {
    let token = localStorage.getItem('authToken');
    if (token){
      setTokenHeader(token);
      dispatch(loginAuth(true))
    }
  }

  useEffect(()=>{
    console.log('authenticated : ' ,authenticated)
  },[authenticated])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={AuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              isAuthenticated= {authenticated}
              exact
            />
          ))}
          {normalRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              isAuthenticated= {authenticated}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
