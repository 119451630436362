export const AUTH_LOGIN = '/login'
export const AUTH_SIGNUP = '/register'

export const GET_CATEGORIES = "/categories"
export const ADD_CATEGORIES = "/categories/create"
export const UPDATE_CATEGORIES = "/categories"
export const DELETE_CATEGORY = "/categories"

export const GET_USERS = "/users"
export const ADD_USERS = "/users/create"
export const UPDATE_USERS = "/users"
export const DELETE_USER = "/users"
export const GET_STORE_SUBSCRIPTION_PLANS = "/users/store/subscriptionPlans"
export const GET_USER_ORDERS_AS_BUYER = "/users"
export const GET_ORDERS = "/orders"

export const GET_LISTING_AVAILABLE = "/inAppSubscriptions/addOn/request"
export const ADD_LISTING_AVAILABLE = "/users/create"
export const UPDATE_LISTING_AVAILABLE = "/inAppSubscriptions/addOn/approve"

export const GET_BRANDS = "/brands"
export const ADD_BRANDS = "/brands/create"
export const UPDATE_BRANDS = "/brands"
export const DELETE_BRAND = "/brands" 
export const GET_BRAND_PRODUCTS = "/brands"

export const GET_PRODUCTS = "/products"
export const ADD_PRODUCTS = "/products/create"
export const UPDATE_PRODUCTS = "/products"
export const DELETE_PRODUCT = "/products"


export const GET_STORE_PASSES = "/storePasses"
export const ADD_STORE_PASSES = "/storePasses/create"
export const UPDATE_STORE_PASSES = "/storePasses"
export const DELETE_STORE_PASS = "/storePasses"

export const GET_SUBSCRIPTION_PLANS = "/inAppSubscriptions"
export const ADD_SUBSCRIPTION_PLANS = "/inAppSubscriptions/create"
export const UPDATE_SUBSCRIPTION_PLANS = "/inAppSubscriptions"
export const DELETE_SUBSCRIPTION_PLAN = "/inAppSubscriptions"

export const GET_SUB_CATEGORIES = "/sub-categories"
export const GET_SUB_SUB_CATEGORIES = "/sub-categories"
export const ADD_SUB_CATEGORIES = "/sub-categories/create"
export const UPDATE_SUB_CATEGORIES = "/sub-categories"
export const DELETE_SUB_CATEGORY = "/sub-categories"

export const GET_TRENDING_HASHTAGS = "/store/trending/hashTags"
export const GET_TRENDING_PROFILES = "/store/trending/profiles"
export const GET_BRANDS_LISTING = "/store/trending/brands"

export const GET_REFERRAL_PROGRAM_SETTING = "/referralPlan"
export const UPLOAD_IMAGE = "/temp_upload"


export const GET_FEATURED = "/featured"
export const ADD_FEATURED = "/featured/create"
export const UPDATE_FEATURED = "/featured"
export const DELETE_FEATURED= "/featured"
