import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    products: [],
    product: {}
}


export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        getProducts: (state, action) => {

            return {
                ...state,
                products: action.payload
            };
        },
        addProducts: (state, action) => {

            return {
                ...state,
                products: {
                    ...state.products,
                    Collection: [...state.products.Collection, action.payload]
                },
            };
        },
        getProductById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                product: action.payload,
            };
        },

        updateProductById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                products: {
                    ...state.products,
                    Collection: state.products.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        },

        deleteProductById: (state, action) => {
            return {
                ...state,
                products: {
                    ...state.products,
                    Collection: state.products.Collection.filter(item => item._id !== action.payload)
                },
            };
        },
        changeRecommended : (state, action) =>
        {
            console.log("action.payload",action.payload)
            return {
                ...state,
                products: {
                    ...state.products,
                    Collection: state.products.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        }

    }
})


export const { getProducts, addProducts, getProductById, updateProductById, deleteProductById,changeRecommended } = productSlice.actions
export default productSlice.reducer