
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Card, CardBody, CardImg, CardTitle, Col, Row, Table } from "reactstrap";
import NoImgFound from "./../../../assets/No-image-found.jpg"
import plus from "./../../../assets/plus.png"
import { CButton } from "@coreui/react";
import { toastConfirm } from "../../../common/toast";
import Breadcrumbs from "../../../common/BreadCrums";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { deleteFeaturedContentByIdThunk, getFeaturedContentThunk } from "../../../features/FeaturedContent/featuredContentThunk";
import "./seller.css"
import moment from 'moment';
import AddSeller from "./models/AddSeller";
import EditSeller from "./models/EditSeller";

const FeaturedSellers = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const featuredSellers = useSelector(state => state.featuredContentReducer.featuredContent);

    const [addModal, setAddModal] = useState(false)
    const [editId, setEditId] = useState('')
    const toggleAddModal = () => setAddModal(!addModal)

    const [editModal, setEditModal] = useState(false)
    const toggleEditModal = () => setEditModal(!editModal)

    useEffect(() => {
        dispatch(getFeaturedContentThunk({
            type:'seller'
        }))
            .unwrap()
            .then((res) => {

            })
            .catch((res) => {

            })
    }, [])

    useEffect(() => {
        console.log(featuredSellers)
    }, [featuredSellers])

    const removeFromFeaturedList = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to delete this?")).fire();
        if (response.isConfirmed) {
            dispatch(deleteFeaturedContentByIdThunk({id , filters:{type:'seller'}})).unwrap()
                .then(() => {

                })
                .catch((error) => {
                    toast.error(error.message /* error.message */, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    }


    return (
        <>
            <AddSeller isOpen={addModal} toggle={toggleAddModal} />
            <EditSeller isOpen={editModal} toggle={toggleEditModal} editId={editId} setEditId={setEditId} />
            <Breadcrumbs title="Featured Sellers" />
            <Row className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 product-grid featured-sellers">
                {featuredSellers?.Collection?.length ? featuredSellers?.Collection.map((item, index) => {
                    return (
                        <>
                            <Col mg={6} xl={3} key={index + 1} className="normalCard position-relative">

                                <Card className="featured-sellers-card">

                                    <CardBody>
                                        <CardTitle tag="h5" className="text-center">
                                            {item.user?.name}
                                        </CardTitle>
                                        <div className="image-container mb-3">
                                            <CardImg
                                                top
                                                className="img-fluid img"
                                                src={item.user?.image !== '' ? `${process.env.REACT_APP_ASSET_URL}${item.user?.image}` : NoImgFound}
                                                alt={item.user?.name}
                                            />
                                        </div>
                                        <Table
                                            bordered
                                        >
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Started From :
                                                    </td>
                                                    <td>
                                                        {moment(item.startDate).format('DD-MM-YYYY')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Ends on :
                                                    </td>
                                                    <td>
                                                        {moment(item.expiryDate).format('DD-MM-YYYY')}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Status :
                                                    </td>
                                                    <td>
                                                        {item.isOnHold == true ? <Badge color="warning">Paused</Badge> : <Badge color="success">Running</Badge>}
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </Table>
                                    </CardBody>

                                </Card>

                                <div className="edit">
                                    <CButton
                                        variant="text"
                                        className="newEditIcon p-0"
                                        onClick={() => {
                                            toggleEditModal();
                                            setEditId(item.user._id)
                                        }}
                                    >
                                        <box-icon type="solid" name="edit" title="Edit"></box-icon>
                                    </CButton>
                                    <CButton
                                        variant="text"
                                        className="newDeleteIcon p-0"
                                        onClick={() => removeFromFeaturedList(item.user._id)}
                                    >
                                        <box-icon type="solid" name="trash" title="Delete"></box-icon>

                                    </CButton>
                                </div>
                            </Col>
                        </>
                    )
                })  : (
                    
                    <div className="col-md-12 text-center p-5">
                        <p className="h3">No Record Found</p>   
                    </div>
            )}
                <div
                    className="addButtonFixed"
                    onClick={() => {
                        toggleAddModal()
                    }}
                >
                    <img

                        className=""
                        src={plus}
                        alt="Add Featured Seller"
                    />
                </div>
            </Row>
        </>
    );
}

export default FeaturedSellers;