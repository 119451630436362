
import React, { useEffect, useState } from "react";
import dummyImage from "./../../assets/images/avatar-2.png"
import NoImgFound from "./../../assets/No-image-found.jpg"

import './Users.css'
import Breadcrumbs from "../../common/BreadCrums";
import UserStore from "./ChildComponets/UserStore";
import UserLiveStreams from "./ChildComponets/UserLiveStreams";
import UserTransactions from "./ChildComponets/UserTransactions";
import UserReviews from "./ChildComponets/UserReviews";
import UserPlacedOrders from "./ChildComponets/UserPlacedOrders";
import UserRecievedOrders from "./ChildComponets/UserRecievedOrders";
import UserAccountSetting from "./ChildComponets/UserAccountSetting";
import UserProducts from "./ChildComponets/UserProducts";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserByIdThunk } from "../../features/User/userThunk";

const UserView = () => {
    document.title = "User Profile View"
    const [selectedTab, setSelectedTab] = useState('Store');
    const params = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.userReducer.user);

    useEffect(() => {
        console.log('mai change ho gyi aa ', selectedTab)
    }, [selectedTab])


    useEffect(() => {
        dispatch(getUserByIdThunk(params.id))
    }, [])



    const getChildComponents = () => {
        switch (selectedTab) {
            case 'Store':
                return <UserStore  />;
                break;
            case 'Products':
                return <UserProducts userId={params.id} />;
                break;
            case 'LiveStreams':
                return <UserLiveStreams userId={params.id}/>;
                break;
            case 'Transactions':
                return <UserTransactions userId={params.id}/>;
                break;
            case 'Reviews':
                return <UserReviews userId={params.id}/>;
                break;
            case 'PlacedOrders':
                return <UserPlacedOrders userId={params.id}/>;
                break;
            case 'RecievedOrders':
                return <UserRecievedOrders userId={params.id}/>;
                break;
            case 'Account':
                return <UserAccountSetting userId={user}/>;
                break;

            default:
                break;
        }
    }


    return (<>
        <Breadcrumbs title="User Profile " curent="user detail" />
        <div className="container">
            <div className="main-body">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="card pb-2">
                            <div className="card-body">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img src={user.image ? process.env.REACT_APP_ASSET_URL+user.image : NoImgFound} alt="Admin" className="rounded-circle p-1 bg-primary" width="110" />
                                    <div className="mt-3">
                                        <h4>{user?.name}</h4>
                                        <p className="text-muted font-size-sm">{user?.email}</p>
                                        <p className="text-muted font-size-sm">@{user?.username}</p>
                                        {/* <button className="btn btn-primary m-2">Edit</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="card p-2">
                            <div className="card-body">
                                <div className="row row-cols-1 row-cols-md-3 row-cols-xl-4">
                                    <div className="col-md-4 col-sm-12">
                                        <div className="card radius-10 bg-primary bg-gradient">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-white">Live-Streams</p>
                                                        <h4 className="my-1 text-white">{user?.liveStreams ?? 0}</h4>
                                                    </div>
                                                    <div className="text-white ms-auto font-35"><i className='bx bx-video-recording'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="card radius-10 bg-danger bg-gradient">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-white">Followers</p>
                                                        <h4 className="my-1 text-white">{user?.followers?.length}</h4>
                                                    </div>
                                                    <div className="text-white ms-auto font-35"><i className='bx bx-user-circle'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="card radius-10 bg-warning bg-gradient">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-dark">Following</p>
                                                        <h4 className="text-dark my-1">{user?.following?.length}</h4>
                                                    </div>
                                                    <div className="text-dark ms-auto font-35"><i className='bx bx-user-circle'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="card radius-10 bg-primary bg-gradient">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-white">Subscribers</p>
                                                        <h4 className="my-1 text-white">{user?.subscriptions?.length}</h4>
                                                    </div>
                                                    <div className="text-white ms-auto font-35">
                                                        <i className='bx bx-user-circle'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="card radius-10 bg-danger bg-gradient">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-white">Total Earning</p>
                                                        <h4 className="my-1 text-white">$ --,---</h4>
                                                    </div>
                                                    <div className="text-white ms-auto font-35"><i className='bx bx-dollar'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-12">
                                        <div className="card radius-10 bg-danger bg-gradient">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <p className="mb-0 text-white">Rating</p>
                                                        <h4 className="my-1 text-white">{user?.rating ?? 0}</h4>
                                                    </div>
                                                    <div className="text-white ms-auto font-35"><i className='bx bx-star'></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card p-1">
                            <ul className="nav nav-tabs nav-primary justify-content-around" role="tablist">
                                <li className="nav-item" onClick={() => {
                                    setSelectedTab('Store')
                                }}>
                                    <button
                                        className="nav-link active" data-bs-toggle="tab" role="tab" aria-selected="true">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-icon"><i className=' bx bx-store-alt font-18 me-1'></i>
                                            </div>
                                            <div className="tab-title">Store Details</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setSelectedTab('Products')
                                }}>
                                    <button
                                        className="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-icon"><i className='bx bx-sitemap font-18 me-1'></i>
                                            </div>
                                            <div className="tab-title">Products</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setSelectedTab('LiveStreams')
                                }}>
                                    <button className="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-icon"><i className='bx bx-video-recording font-18 me-1'></i>
                                            </div>
                                            <div className="tab-title">Live Streams</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setSelectedTab('Transactions')
                                }}>
                                    <button className="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-icon"><i className='bx bx-dollar font-18 me-1'></i>
                                            </div>
                                            <div className="tab-title">Transactions</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setSelectedTab('Reviews')
                                }}>
                                    <button className="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-icon"><i className='bx bx-star font-18 me-1'></i>
                                            </div>
                                            <div className="tab-title">Reviews</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setSelectedTab('PlacedOrders')
                                }}>
                                    <button className="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-icon"><i className='bx bx-box font-18 me-1'></i>
                                            </div>
                                            <div className="tab-title">Orders (As Buyer)</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setSelectedTab('RecievedOrders')
                                }}>
                                    <button className="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-icon"><i className='bx bx-box font-18 me-1'></i>
                                            </div>
                                            <div className="tab-title">Orders (As Seller)</div>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" onClick={() => {
                                    setSelectedTab('Account')
                                }}>
                                    <button className="nav-link" data-bs-toggle="tab" role="tab" aria-selected="false">
                                        <div className="d-flex align-items-center">
                                            <div className="tab-icon"><i className='bx bx-cog font-18 me-1'></i>
                                            </div>
                                            <div className="tab-title">Account Setting</div>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content py-3">
                                <div style={{ 'minHeight': "500px" }} >
                                    <div className="row px-3">
                                        {getChildComponents()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default UserView;