import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import { addBrands, deleteBrandById, getBrands, getBrandById, updateBrandById, getBrandProducts } from "./brandSlice";


export const getBrandThunk = createAsyncThunk('brands', async (filters, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.post(url.GET_BRANDS, filters)  //AuthLogin(data);

    thunkApi.dispatch(getBrands(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});


export const getBrandProductsThunk = createAsyncThunk('brand/products', async (filterData, thunkApi) => {
  try {
  
    const response = await axiosApi.post(`${url.GET_BRAND_PRODUCTS}/${filterData.id}/products`, filterData)  //AuthLogin(data);

    thunkApi.dispatch(getBrandProducts(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getBrandByIdThunk = createAsyncThunk('brands/edit', async (id, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_BRANDS}/${id}`)  //AuthLogin(data);
    thunkApi.dispatch(getBrandById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const updateBrandByIdThunk = createAsyncThunk('brands/update', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    let image = data.image;
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }
    console.log('image ', image)

    const response = await axiosApi.post(`${url.UPDATE_BRANDS}/${data._id}/edit`, { name: data.name,status: data.status, image });  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(updateBrandById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const deleteBrandByIdThunk = createAsyncThunk('brands/delete', async (id, thunkApi) => {
  try {

    const response = await axiosApi.delete(`${url.DELETE_BRAND}/${id}`);  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(deleteBrandById(id))
    return thunkApi.fulfillWithValue(id)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const addBrandThunk = createAsyncThunk('brands/add', async ( data , thunkApi) => {
  try {
    let image = []
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }else{
      throw new Error('image is required ')
    }
    console.log('image ', image)

    const response = await axiosApi.post(url.ADD_BRANDS, { name: data.name, status:data.status ,image });  //AuthLogin(data);
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(addBrands(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});