import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { addInAppSubscriptions, deleteInAppSubscriptionsById, getInAppSubscriptions, getInAppSubscriptionsById, updateInAppSubscriptionsById } from "./inAppSubscriptionSlice";


export const getInAppSubscriptionsThunk = createAsyncThunk('inAppSubscripitons', async (filters, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.post(url.GET_SUBSCRIPTION_PLANS, filters)  //AuthLogin(data);

    thunkApi.dispatch(getInAppSubscriptions(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getInAppSubscriptionsByIdThunk = createAsyncThunk('inAppSubscripitons/edit', async (id, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_SUBSCRIPTION_PLANS}/${id}`);
    thunkApi.dispatch(getInAppSubscriptionsById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const updateInAppSubscriptionsByIdThunk = createAsyncThunk('inAppSubscripitons/update', async (data, thunkApi) => {
  try {

    const response = await axiosApi.post(`${url.UPDATE_SUBSCRIPTION_PLANS}/${data._id}/edit`, {
      name: data?.name,
      description: data?.description,
      playStorePlanId: data?.playStorePlanId,
      appStorePlanId: data?.appStorePlanId,
      marketPlaceCutOff: data?.marketPlaceCutOff,
      auctionCutOff: data?.auctionCutOff,
      price: data?.price,
      listingLimit: data?.listingLimit,
      liveStreamEnabled: data?.liveStreamEnabled,
      subscriptionAccessEnabled: data?.subscriptionAccessEnabled,
      subscriptionCutOff: data?.subscriptionCutOff,
    });  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(updateInAppSubscriptionsById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const deleteInAppSubscriptionsByIdThunk = createAsyncThunk('inAppSubscripitons/delete', async (id, thunkApi) => {
  try {

    const response = await axiosApi.delete(`${url.DELETE_SUBSCRIPTION_PLAN}/${id}`);  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(deleteInAppSubscriptionsById(id))
    return thunkApi.fulfillWithValue(id)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const addInAppSubscriptionThunk = createAsyncThunk('inAppSubscripitons/add', async (data, thunkApi) => {
  try {

    const response = await axiosApi.post(url.ADD_SUBSCRIPTION_PLANS, data);  //AuthLogin(data);
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(addInAppSubscriptions(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});