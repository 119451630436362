
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import './Orders.css'
import Breadcrumbs from "../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import { getOrdersThunk } from "../../features/Orders/orderThunk";
import moment from "moment";

const Orders = () => {
    document.title = "Orders Listing"
    const dispatch = useDispatch();
    const orders = useSelector(state => state.orderReducer.orders);

    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        "limit": 10,
        "page": 1,
    });
    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
        });
    };


    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'order': '',
            "limit": 10,
            "page": 1
        })
    }



    useEffect(() => {
        console.log('calling... ')
        dispatch(getOrdersThunk(filterData))
    }, [filterData])





    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                console.log('orders?.Pagination?.current_page : ', orders?.Pagination?.current_page)
                if ((orders?.Pagination?.current_page - 1) != 0) {

                    return ((orders?.Pagination?.current_page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'OrderId',
            selector: row => row.orderId,
            sortable: false,
            actualKey: 'orderId',
        },
        {
            name: 'Type',
            selector: (row) => {
                if (row.isLiveStreamOrder) {
                    return <span className="badge bg-secondary">Live Stream</span>
                } else {
                    return <span className="badge bg-secondary">Normal</span>
                }
            } ,
            sortable: true,
            actualKey: 'isLiveStreamOrder',
        },
        {
            name: 'User',
            selector: (row) => {
                if (row.user?.name)
                    return <Link to={`users/${row.user?._id}/view`} className="actionIcons">
                        {row.user.name}
                    </Link>
            },
            sortable: true,
            actualKey: 'user.name',
        },
        {
            name: 'Items',
            selector: row => row.itemsCount,
            sortable: true,
            actualKey: 'itemsCount',
        },
        {
            name: 'Payment Status',
            selector: (row) => {
                if (row.paymentStatus == 'success') {
                    return <span className="badge bg-success">Success</span>
                } else if (row.paymentStatus == 'pending') {
                    return <span className="badge bg-secondary">Pending</span>
                } else {
                    return <span className="badge bg-danger">Failed</span>
                }
            },
            sortable: true,
            actualKey: 'paymentStatus',
        },
        {
            name: 'Created At',
            selector: row => moment(row.createdAt).format('MM/DD/YYYY'),
            sortable: false,
            actualKey: 'createdAt',
        },
        {
            name: "Actions",
            width: "200px",
            selector: row => (
                <>
                    <Link to={`orders/${row._id}`} >
                        <button type="button" className="btn btn-sm btn-outline-primary px-5" title="View Order">View</button>
                    </Link>
                </>
            ),
        },
    ];



    return (<>
        <Breadcrumbs title="Store Orders" />
        <Row>
            <Col xl={12}>
                <div className="mb-2">
                    <Link to={'/orders/add'} className="btn btn-primary w-md">Add +</Link>
                </div>
                <CCardGroup className="mb-2">
                    <CCard>
                        <CCardBody>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="w-100">
                                        <label>Search :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            value={filterData?.search}
                                            onChange={e => {
                                                handleFilterState('search', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1 justify-content-right">
                                    <div className="mt-3 justify-content-right">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            id="search"
                                            value="Reset"
                                            onClick={() => ResetFilters()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCardGroup>

                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={orders?.Collection}
                                pagination
                                paginationServer
                                paginationTotalRows={orders?.Pagination?.total_records}
                                paginationDefaultPage={filterData?.page}
                                rowsPerPageOptions={[10, 20, 30]}
                                paginationPerPage={filterData.limit}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                paginationComponentOptions={{ selectAllRowsItem: true, }}
                                onChangePage={(page, totalRows) => {
                                    handleFilterState('page', page)
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>

    </>);
}

export default Orders;