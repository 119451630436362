
import React, { useEffect,  useState } from "react";
import {
    Row,
    Label,
    Button,
} from "reactstrap"
import './StorePasses.css'
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStorePassByIdThunk, updateStorePassByIdThunk } from "../../features/StorePass/storePassThunk";
import { useDispatch } from "react-redux";

const EditStorePasses = () => {
    document.title = "Edit Store Pass"
    const dispatch = useDispatch();
    let history = useHistory();
    let params = useParams();
    const storePass = useSelector((state) => state.storePassReducer.storePass)
    const [data, setData] = useState({
        marketPlaceCutOff: '',
        auctionCutOff: '',
        listingLimit: '',
        liveStreamEnabled: '',
        subscriptionCutOff: '',
        subscriptionCutOff: '',
    })

    const stepAddSchema = Yup.object().shape({
        marketPlaceCutOff: Yup.number().required('This field is required'),
        auctionCutOff: Yup.number().required('This field is required'),
        listingLimit: Yup.number().required('This field is required'),
        liveStreamEnabled: Yup.boolean().required('This field is required'),
        subscriptionCutOff: Yup.number().required('This field is required'),
    })

    const handleUpdateBrand = async () => {
        console.log('data : ', data)
        dispatch(updateStorePassByIdThunk(data))
            .unwrap()
            .then((res) => {
                console.log('response : ', res)
                if (res.status) {
                    history.push('/store-passes');
                }
            }).catch((err) => {
                console.log(err.message);
            });

    }

    useEffect(() => {
        dispatch(getStorePassByIdThunk(params?.id)).unwrap()
            .then((res) => {
                
            }).catch((error) => {
                toast.error(error.message /* error.message */, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });

    }, []);

    useEffect(()=>{
        setData(storePass)
    },[storePass])
    return (<>
        <Row>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Edit Brand</h5>
                    <hr />
                    <Formik
                        initialValues={
                            {
                                marketPlaceCutOff: data.marketPlaceCutOff,
                                auctionCutOff: data.auctionCutOff,
                                listingLimit: data.listingLimit,
                                liveStreamEnabled: data.liveStreamEnabled,
                                subscriptionCutOff: data.subscriptionCutOff,
                            }
                        }
                        enableReinitialize={true}
                        validationSchema={stepAddSchema}
                        onSubmit={(values, actions) => {
                            handleUpdateBrand()
                            return false;
                        }}
                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Form
                            >
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                       MarketPlace Cut-Off to PlatForm (in %)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="marketPlaceCutOff"
                                        name="marketPlaceCutOff"
                                        onChange={e => {
                                            setData({ ...data, marketPlaceCutOff: e.target.value })
                                            setFieldValue('marketPlaceCutOff', e.target.value)
                                        }}
                                    />
                                    {errors.marketPlaceCutOff && touched.marketPlaceCutOff ? (
                                        <div className="text-danger input-error">
                                            {errors.marketPlaceCutOff}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Auction Cut-Off to Platform (in %)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="auctionCutOff"
                                        name="auctionCutOff"
                                        onChange={e => {
                                            setData({ ...data, auctionCutOff: e.target.value })
                                            setFieldValue('auctionCutOff', e.target.value)
                                        }}
                                    />
                                    {errors.auctionCutOff && touched.auctionCutOff ? (
                                        <div className="text-danger input-error">
                                            {errors.auctionCutOff}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Product Listing Limit 
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="listingLimit"
                                        name="listingLimit"
                                        onChange={e => {
                                            setData({ ...data, listingLimit: e.target.value })
                                            setFieldValue('listingLimit', e.target.value)
                                        }}
                                    />
                                    {errors.listingLimit && touched.listingLimit ? (
                                        <div className="text-danger input-error">
                                            {errors.listingLimit}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Subscription Plan's Cut-Off to platform  (in %)
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="subscriptionCutOff"
                                        name="subscriptionCutOff"
                                        onChange={e => {
                                            setData({ ...data, subscriptionCutOff: e.target.value })
                                            setFieldValue('subscriptionCutOff', e.target.value)
                                        }}
                                    />
                                    {errors.subscriptionCutOff && touched.subscriptionCutOff ? (
                                        <div className="text-danger input-error">
                                            {errors.subscriptionCutOff}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="Type">Add Live Stream Access ?  </Label>
                                    <Field name="liveStreamEnabled" className="form-control"
                                        
                                        onChange={e => {
                                            setData({ ...data, liveStreamEnabled: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="liveStreamEnabled" className="form-control input-color " name="liveStreamEnabled1" onChange={e => {
                                                    setData({ ...data, liveStreamEnabled: e.target.value })
                                                    setFieldValue('liveStreamEnabled', e.target.value)
                                                }}
                                                value={data.liveStreamEnabled}>
                                                    <option value="">Choose</option>
                                                    <option value={true}>Yes</option>
                                                    <option value={false}>No</option>
                                                </select>
                                                {form.touched.liveStreamEnabled &&
                                                    form.errors.liveStreamEnabled ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.liveStreamEnabled}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>


                                </div>
                                <Button type="submit" color="primary" >
                                    Update Pass
                                </Button>

                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

        </Row>
    </>);
}

export default EditStorePasses;