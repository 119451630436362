
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import './Products.css'
import Breadcrumbs from "../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import { getProductThunk } from "../../features/Product/productThunk";
import { changeRecommendedThunk } from "../../features/Product/productThunk";

const Products = () => {
    document.title = "Products Listing"
    const [modal, setModal] = useState(false);
    const [singleProduct, setSingleProduct] = useState(null);
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();
    const products = useSelector(state => state.productReducer.products);
    const [isRecommended, setIsRecommended] = useState({});
    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        "limit": 10,
        "page": 1,
    });
    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
        });
    };


    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'order': '',
            "limit": 10,
            "page": 1
        })
    }

    const showProductPopup = (product) => {
        setSingleProduct(product);
        console.log(product)
        toggle();
    }

    useEffect(() => {
        dispatch(getProductThunk(filterData))
    }, [filterData])


    useEffect(() => {
        console.log('product Data: ', singleProduct)
    }, [singleProduct])

    const handleCheckboxChange = (prevState, checked) => {
        dispatch(changeRecommendedThunk({
            ...prevState,
            isRecommended :checked
        }))
    };
    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                console.log('products?.Pagination?.current_page : ', products?.Pagination?.current_page)
                if ((products?.Pagination?.current_page - 1) != 0) {

                    return ((products?.Pagination?.current_page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'Product',
            selector: (row) => {
                return <button className="btn btn-sm  btn-outline-primary" onClick={() => {
                    showProductPopup(row);
                }}>
                    Show
                </button>
            },
            sortable: false,
        },
        {
            name: 'Name',
            selector: row => row.title,
            sortable: false,
            actualKey: 'title',
        },

        {
            name: 'Seller',
            selector: (row) => {
                if (row.seller?.name)
                    return <Link to={`users/${row.seller?._id}/view`} className="actionIcons">
                        {row.seller.name}
                    </Link>
            },
            sortable: true,
            actualKey: 'seller.name',
        },
        {
            name: 'Brand',
            selector: (row) => {
                if (row.brand?.name)
                    return <Link to={`brands/${row.brand?._id}/view`} className="actionIcons">
                        {row.brand.name}
                    </Link>
            },
            sortable: true,
            actualKey: 'brand.name',
        },
        {
            name: 'Category',
            selector: row => row.category?.name,
            sortable: true,
            actualKey: 'category.name',
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: false,
            actualKey: 'type',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: false,
            actualKey: 'status',
        },
        {
            name: 'Recommended',
            selector: row => (
                <input
                    type="checkbox"
                    checked={row.isRecommended}
                    onChange={(e) => handleCheckboxChange(row, e.target.checked)}
                />
            ),
            sortable: false,
            wrap: true,
        },
        {
            name: 'Created At',
            selector: row => row.createdAt,
            sortable: false,
            actualKey: 'createdAt',
        },
        // {
        //     name: "Actions",
        //     width: "200px",
        //     selector: row => (
        //         <>
        //             <Link to={`products/${row._id}/edit`} >
        //                 <box-icon type='solid' size='sm' color='#008cff' className="px-2" name='edit' title="Edit Store Pass"></box-icon>
        //             </Link>
        //             <box-icon name='trash' type='solid' size='sm' color='#008cff' className="px-2 cursor-pointer"
        //                 onClick={() => {
        //                     // alert(row._id)
        //                     // deleteProductById(row._id);
        //                 }}>

        //             </box-icon>
        //         </>
        //     ),
        // },
    ];



    return (<>
        <Breadcrumbs title="Product Listing" />
        <Row>
            <Col xl={12}>
                <div className="mb-2">
                    <Link to={'/products/add'} className="btn btn-primary w-md">Add +</Link>
                </div>
                <CCardGroup className="mb-2">
                    <CCard>
                        <CCardBody>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="w-100">
                                        <label>Search :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            value={filterData?.search}
                                            onChange={e => {
                                                handleFilterState('search', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1 justify-content-right">
                                    <div className="mt-3 justify-content-right">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            id="search"
                                            value="Reset"
                                            onClick={() => ResetFilters()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCardGroup>

                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={products?.Collection}
                                pagination
                                paginationServer
                                paginationTotalRows={products?.Pagination?.total_records}
                                paginationDefaultPage={filterData?.page}
                                rowsPerPageOptions={[10, 20, 30]}
                                paginationPerPage={filterData.limit}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                paginationComponentOptions={{ selectAllRowsItem: true, }}
                                onChangePage={(page, totalRows) => {
                                    handleFilterState('page', page)
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>
        <Modal className="modal-dialog modal-xl" isOpen={modal} toggle={toggle}>
            <ModalBody>
                <div className="productCrossBtn">
                    <box-icon type='solid' name='x-circle' className="icon" onClick={() => {
                        toggle();
                    }}></box-icon>
                </div>
                {singleProduct ?
                    <div className="row g-0">
                        <div className="col-md-4 border-end">
                            <img src={process.env.REACT_APP_ASSET_URL + singleProduct?.variants[0]?.images[0]} className="img-fluid" alt="..." />
                            <div className="row mb-3 row-cols-auto g-2 justify-content-center mt-3">
                                <div className="col"><img src={process.env.REACT_APP_ASSET_URL + singleProduct?.variants[0]?.images[0]} style={{ "width": "70px" }} className="border rounded cursor-pointer" alt="" /></div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h4 className="card-title">{singleProduct.title}</h4>
                                <div className="d-flex gap-3 py-3">
                                    <div className="cursor-pointer">
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-secondary'></i>
                                    </div>
                                    <div>{singleProduct?.reviews?.length ?? 0} reviews</div>
                                    <div>{singleProduct?.likes?.length ?? 0} Likes</div>
                                    <div className="text-success"><i className='bx bxs-cart-alt align-middle'></i> {singleProduct?.orders?.length ?? 0} orders</div>
                                </div>
                                <div className="mb-3">
                                    <span className="price h4">${singleProduct?.variants[0]?.price}</span>
                                </div>
                                <p className="card-text fs-6">{singleProduct.description} Likes</p>
                                <hr />
                                <p className="card-text fs-6">Available Options</p>


                                {singleProduct.options.map((option) => {

                                    return <div className="row row-cols-auto row-cols-1 row-cols-md-3 align-items-center">
                                        <div className="col">
                                            <label className="form-label">{option.title}</label>
                                            <div>


                                                {option.values.map((val) => {

                                                    return <div className="chip chip-lg">{val}</div>
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                })}
                            </div>
                        </div>
                    </div>
                    : ''}
            </ModalBody>
        </Modal>
    </>);
}

export default Products;