import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authenticated : false
}

export const  authSlice = createSlice({
    name : 'auth',
    initialState,
    reducers :{
        loginAuth : (state , action) => {
            
            return {
                ...state,
                authenticated :true
              };
        },
        
        logoutAuth : (state , action) => {
            console.log('logout triggered');
            localStorage.removeItem('authToken')
            return {
                ...state,
                user: {},
                authenticated :false
              };

        }
    }
})


export const {loginAuth,logoutAuth} = authSlice.actions
export default authSlice.reducer