
import React, { useRef, useState } from "react";
import {
    Row,
    Label,
    Button,
} from "reactstrap"
import './Brands.css'
import { Field, Form, Formik } from "formik"

import Dropzone from "react-dropzone";
import * as Yup from "yup"
import { fireToast } from "../../common/toast";
import cloud from "../../assets/images/cloud-file-download.svg"
import { useDispatch } from "react-redux";
import { addBrandThunk } from "../../features/Brand/brandThunk";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const AddBrands = () => {
    document.title = "Add New Brand"
    const dispatch = useDispatch();
    let history = useHistory();

    const [data, setData] = useState({
        name: "",
        status: "",
    })
    const uploadRef = useRef(null)
    const [image, setImage] = useState({ blob: null, src: "" })

    const stepAddSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        status: Yup.string().required('Status is required'),
    })

    const handleAddBrand = async (values) => {
        console.log('data : ' , data)
        dispatch(addBrandThunk(data))
            .unwrap()
            .then((res) => {
                console.log('res ponse : ' ,res)
                if (res.status) {
                    history.push('/brands');
                }
            }).catch((err) => {
                console.log(err.message);
            });

    }



    const handleImageChange = files => {
        const extn = ["image/jpg", "image/png", "image/jpeg"]
        const [file] = files
        setData({ ...data, file })

        if (file && extn.includes(file.type)) {

            if (file.size <= 5242880) {
                setImage({ blob: file, src: window.URL.createObjectURL(file) })
            } else {
                fireToast("error", "image too large")
            }
        } else {
            fireToast(
                "error",
                "Please select a valid image file(only jpg, png and jpeg images are allowed)"
            )
        }
    }


    return (<>
        <Row>
            <div class="card">
                <div class="card-body p-4">
                    <h5 class="card-title">Add New Brand</h5>
                    <hr />
                    <Formik
                        initialValues={{
                            name: "",
                            status: "",
                        }}
                        validationSchema={stepAddSchema}
                        onSubmit={(values, actions) => {
                            handleAddBrand(values)
                        }}
                    >
                        {({ values, setValues, setFieldValue, errors, touched }) => (
                            <Form
                            >
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Brand Name
                                    </Label>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        onChange={e => {
                                            setData({ ...data, name: e.target.value })
                                            setFieldValue('name', e.target.value)
                                        }}
                                    />
                                    {errors.name && touched.name ? (
                                        <div className="text-danger input-error">
                                            {errors.name}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="Type">Status</Label>
                                    <Field name="status" className="form-control"
                                        onChange={e => {
                                            setData({ ...data, status: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="status" className="form-control input-color " name="status1" onChange={e => {
                                                    setData({ ...data, status: e.target.value })
                                                    setFieldValue('status', e.target.value)
                                                }}>
                                                    <option value="">Choose</option>
                                                    <option value="1">Active</option>
                                                    <option value="2">In-Active</option>
                                                </select>
                                                {form.touched.status &&
                                                    form.errors.status ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>


                                </div>

                                <div >
                                    <Label htmlFor="formrow-firstname-Input">
                                        Image
                                    </Label>
                                    <div className="text-center">

                                        <div className="text-center">
                                            <div className="mb-3 dragdrop-container">
                                                <input
                                                    ref={uploadRef}
                                                    id="upload"
                                                    hidden
                                                    name="image"
                                                    accept="image/*"
                                                    type="file"
                                                    onChange={e =>
                                                        handleImageChange(e.target.files)
                                                    }
                                                />
                                                {image.src ? (
                                                    <div className="commonImgs">
                                                        <img
                                                            className="every-img"
                                                            src={image.src ? image.src : cloud}
                                                            alt=""
                                                            onClick={() => {
                                                                uploadRef.current.click()
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="drag-n-drop-container">
                                                        <div>
                                                            <Dropzone
                                                                accept="image/*"
                                                                multiple={false}
                                                                onDrop={acceptedFiles => {
                                                                    handleImageChange(acceptedFiles)
                                                                }}
                                                            >
                                                                {({
                                                                    getRootProps,
                                                                    getInputProps,
                                                                    isDragActive,
                                                                }) => (
                                                                    <section>
                                                                        <div
                                                                            className="drop-area"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <img
                                                                                width={60}
                                                                                src={cloud}
                                                                                alt=""
                                                                            />
                                                                            <input
                                                                                {...getInputProps()}
                                                                                accept="image/*"
                                                                                multiple={false}
                                                                            />

                                                                            {isDragActive ? (
                                                                                <div>
                                                                                    Drop your image file here
                                                                                </div>
                                                                            ) : (
                                                                                <p>
                                                                                    Drag n drop image file here,
                                                                                    or click to select <br />
                                                                                    <small className="text-center">
                                                                                        <strong>
                                                                                            Supported files:
                                                                                        </strong>{" "}
                                                                                        jpeg, jpg, png. | Will be
                                                                                        resized to: 1920x1080 px.
                                                                                    </small>
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p className="fw-bold">
                                            Note:
                                            <span className="text-danger mx-2 text-align-left">
                                                Supported image formats are:&nbsp;jpg, png and
                                                jpeg only
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <Button type="submit" color="primary" >
                                    Save Changes
                                </Button>

                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

        </Row>
    </>);
}

export default AddBrands;