import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import {  getFeaturedContent, getFeaturedContentById } from "./featureContentSlice";


export const getFeaturedContentThunk = createAsyncThunk('featuredContent', async (filters, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_FEATURED}?type=${filters.type}`);

    thunkApi.dispatch(getFeaturedContent(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});


export const getFeaturedContentByIdThunk = createAsyncThunk('featuredContent/edit', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    console.log('getFeaturedContentByIdThunk : ' , data)
    let {editId ,filters} = data;

    const response = await axiosApi.get(`${url.GET_FEATURED}/${editId}?type=${filters.type}`)
    // thunkApi.dispatch(getFeaturedContentById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const updateFeaturedContentByIdThunk = createAsyncThunk('featuredContent/update', async (requestData, thunkApi) => {
  try {
    let { data , handleClose ,filters} = requestData ;
    let id = filters.type == 'seller' ? data.seller._id : data.product._id
      const response = await axiosApi.post(`${url.UPDATE_FEATURED}/${id}/edit?type=${filters.type}`,
      {
        startDate:data.startDate,
        expiryDate: data.expiryDate,
        isOnHold:  data.status == '0' ? false : true
      }
      );


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    handleClose();
    thunkApi.dispatch(getFeaturedContentThunk(filters))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const deleteFeaturedContentByIdThunk = createAsyncThunk('featuredContent/delete', async (data, thunkApi) => {
  try {
    console.log('deleteFeaturedContentByIdThunk  : ' ,data)
    let { id , filters} = data
    const response = await axiosApi.delete(`${url.DELETE_FEATURED}/${id}?type=${filters.type}`);  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(getFeaturedContentThunk(filters))
    return thunkApi.fulfillWithValue(id)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const addFeaturedContentThunk = createAsyncThunk('featuredContent/add', async (requestData, thunkApi) => {
  try {
    let  { data, filters, handleClose} = requestData;
    const response = await axiosApi.post(`${url.ADD_FEATURED}?type=${filters.type}`,
      {
        id: data.seller._id,
        startDate:data.startDate,
        expiryDate: data.expiryDate,
        isOnHold:  data.status == '0' ? false : true
      }
    );
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(getFeaturedContentThunk(filters))
    handleClose();
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});