
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  Col, Modal, ModalBody,  ModalHeader, Row } from "reactstrap";
import './StorePasses.css'
import Breadcrumbs from "../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import { toastConfirm } from "../../common/toast";
import { toast } from "react-toastify";
import { deleteStorePassByIdThunk, getStorePassThunk } from "../../features/StorePass/storePassThunk";

const StorePasses = () => {
    document.title = "StorePass Listing"
    const [modal, setModal] = useState(false);
    const [secretKey, setSecretKey] = useState('');
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();
    const storePasses = useSelector(state => state.storePassReducer.storePasses);
    const inputRef = useRef(null);

    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        'order': '',
        "limit": 10,
        "current_page": 1
    });
    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
        });
    };
    const deleteStorePassById = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to delete this?")).fire();
        if (response.isConfirmed) {
            dispatch(deleteStorePassByIdThunk(id)).unwrap()
                .then(() => {

                })
                .catch((error) => {
                    toast.error(error.message /* error.message */, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    }

    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'order': '',
            "limit": 10,
            "current_page": 1
        })
    }
    const showSecretekeyPopup = (key) => {
        setSecretKey(key);
        toggle();
    }

        const copySecreteKey = () => {
          inputRef.current.select('secretKey');
          document.execCommand('copy');

          // Deselect the input field
          setTimeout(() => {
            console.log('bluring')
            inputRef.current.blur();
          }, 1000);
          toast.success('Copied!' /* error.message */, {
            position: toast.POSITION.TOP_RIGHT
          });
        };

    useEffect(() => {
        dispatch(getStorePassThunk(filterData))
    }, [filterData])


    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                console.log('storePasses?.Pagination?.current_page : ', storePasses?.Pagination?.current_page)
                if ((storePasses?.Pagination?.current_page - 1) != 0) {

                    return ((storePasses?.Pagination?.current_page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'Pass Key',
            selector: (row) => {
                return <button className="btn btn-sm  btn-outline-primary" onClick={()=> {
                    showSecretekeyPopup(row.secretKey);
                }}>
                    Show
                </button>
            },
            sortable: false,
            actualKey: 'secretKey',
        },
        {
            name: 'Product Listing Limit',
            selector: row => row.listingLimit,
            sortable: false,
            actualKey: 'listingLimit',
        },
        {
            name: 'Live Stream',
            selector: row => row.liveStreamEnabled ? 'Allowed' : 'Not-Allowed',
            sortable: false,
            actualKey: 'liveStreamEnabled',
        },
        {
            name: 'Subscription CutOff',
            selector: row => row.subscriptionCutOff,
            sortable: false,
            actualKey: 'subscriptionCutOff',
        },
        {
            name: 'Market Place CutOff',
            selector: row => row.marketPlaceCutOff,
            sortable: false,
            actualKey: 'marketPlaceCutOff',
        },
        {
            name: 'Market Place CutOff',
            selector: row => row.auctionCutOff,
            sortable: false,
            actualKey: 'auctionCutOff',
        },
        {
            name: 'Status',
            selector: row => row.user ? 'Used' : row.default_plan != true ? 'Un-used' : 'Default Plan',
            sortable: false,
            actualKey: 'user',
        },
        {
            name: "Actions",
            width: "200px",
            selector: row => (
                <>
                    <Link to={`store-passes/${row._id}/edit`} >
                        <box-icon type='solid' size='sm' color='#008cff' className="px-2" name='edit' title="Edit Store Pass"></box-icon>
                    </Link>
                    {row.default_plan != true && (
                        <box-icon
                            name='trash'
                            type='solid'
                            size='sm'
                            color='#008cff'
                            className="px-2 cursor-pointer"
                            onClick={() => {
                                // alert(row._id)
                                deleteStorePassById(row._id);
                            }}
                        ></box-icon>
                    )}

                </>
            ),
        },
    ];



    return (<>
        <Breadcrumbs title="StorePass Listing" />
        <Row>
            <Col xl={12}>
                <div className="mb-2">
                    <Link to={'/store-passes/add'} className="btn btn-primary w-md">Add +</Link>
                </div>
                <CCardGroup className="mb-2">
                    <CCard>
                        <CCardBody>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="w-100">
                                        <label>Search :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            value={filterData?.search}
                                            onChange={e => {
                                                handleFilterState('search', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1 justify-content-right">
                                    <div className="mt-3 justify-content-right">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            id="search"
                                            value="Reset"
                                            onClick={() => ResetFilters()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCardGroup>

                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={storePasses?.Collection}
                                pagination
                                paginationServer
                                paginationTotalRows={storePasses?.Pgaination?.total}
                                paginationDefaultPage={filterData?.current_page}
                                rowsPerPageOptions={[10, 20, 30]}
                                paginationPerPage={filterData.limit}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                paginationComponentOptions={{ selectAllRowsItem: true, }}
                                onChangePage={(page, totalRows) => {
                                    handleFilterState('current_page', page)
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>StorePass Key</ModalHeader>
            <ModalBody>
                <div className="row p-3">
                    <div className="col-md-10">
                        <input type="text" className="form-control" readOnly value={secretKey} ref={inputRef}/>
                    </div>
                    <div className="col-md-2 d-flex justify-content-center">
                    <button className="btn btn-sm btn-outline-primary" onClick={()=> {
                            copySecreteKey();
                        }}>
                            Copy
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </>);
}

export default StorePasses;