import { useFormik } from "formik";
import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { loginUser } from "../features/Auth/authThunk";
import { ToastContainer } from 'react-toastify';
import { isloading } from "../features/Loader/loaderSlice";


const AuthLogin = (props) => {
    document.title = "Admin| Login"
    let dispatch = useDispatch();
    const [authenticated, setauthenticated] = useState(null);
    let history = useHistory();


    useEffect(() => {
        const loggedInUser = localStorage.getItem("authToken");
        console.log(loggedInUser)
        if (loggedInUser != null) {
            setauthenticated(true);
        }
        dispatch(isloading(false))
    }, []);


    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: values => {
            dispatch(loginUser(values))
                .unwrap()
                .then((res) => {
                    history.push('/dashboard');
                }).catch((err) => {
                });
        },
    });
    if (authenticated) {
        history.push('/dashboard');
    } else {
        return (
            <React.Fragment>
                <div className="wrapper bg-login">
                    <div className="section-authentication-signin d-flex align-items-center justify-content-center my-5 my-lg-0">
                        <div className="container-fluid">
                            <div className="row row-cols-1 row-cols-lg-2 row-cols-xl-3">
                                <div className="col mx-auto">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="border p-4 rounded">
                                                <div className="text-center">
                                                    <h3 className="">Sign in</h3>
                                                    <p>Don't have an account yet? <Link to="/signup">Sign up here</Link>
                                                    </p>
                                                </div>
                                                <div className="d-grid">
                                                </div>
                                                <div className="login-separater text-center mb-4"> <span>OR SIGN IN WITH EMAIL</span>
                                                    <hr />
                                                </div>
                                                <div className="form-body">
                                                    <form className="row g-3" onSubmit={formik.handleSubmit}>
                                                        <div className="col-12">
                                                            <label className="form-label">Email Address</label>
                                                            <input type="email" name="email" className="form-control" id="inputEmailAddress" placeholder="Email Address" onChange={formik.handleChange} value={formik.values.email} />
                                                            {formik.touched.email && formik.errors.email ? (
                                                                <div className="text-danger">{formik.errors.email}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-12">
                                                            <label className="form-label">Enter Password</label>
                                                            <div className="input-group" id="show_hide_password">
                                                                <input type="password" name="password" className="form-control border-end-0" id="inputChoosePassword" placeholder="Enter Password"
                                                                    onChange={formik.handleChange} value={formik.values.password} />
                                                            </div>
                                                            {formik.touched.password && formik.errors.password ? (
                                                                <div className="text-danger">{formik.errors.password}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked="false" />
                                                                <label className="form-check-label" >Remember Me</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 text-end">	 <Link to="/forgot-Password">Forgot Password ?</Link>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="d-grid">
                                                                <button type="submit" className="btn btn-primary"><i className="bx bxs-lock-open"></i>Sign in</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </React.Fragment>
        )
    }
}

export default AuthLogin