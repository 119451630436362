import axios from "axios";

export const LoadUsers = async (searchQuery, loadedOptions, { page }) => {
    try {
        // axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem("authToken"))}`;
        console.log(`${process.env.REACT_APP_API_URL}/users?notFeaturedOnly=true&page=${page}`)
        const responseJSON = await axios.post(`${process.env.REACT_APP_API_URL}/users?notFeaturedOnly=true&page=${page}`);
        let users = responseJSON.data.data;
        console.log(page * 10 < users.Pagination.total_records ? true : false)
        return {
            options: users.Collection,
            // hasMore: responseJSON.length >= 1,
            hasMore: page * 10 < users.Pagination.total_records ? true : false,
            additional: {
                page: searchQuery ? 2 : page + 1,
            },
        };
    } catch (error) {
        console.log("err : ", error);
        return {
            options: [],
            hasMore: false,
            additional: { page: 1 },
        };
    }
};


export const LoadProducts = async (searchQuery, loadedOptions, { page }) => {
    try {
        // axios.defaults.headers.common['Authorization'] = `Bearer ${JSON.parse(localStorage.getItem("authToken"))}`;
        console.log(`${process.env.REACT_APP_API_URL}/products?notFeaturedOnly=true&page=${page}`)
        const responseJSON = await axios.post(`${process.env.REACT_APP_API_URL}/products?notFeaturedOnly=true&page=${page}`);
        let products = responseJSON.data.data;

        products.Collection = products.Collection.map((product) => {
            return { _id: product._id, name: product.title }

        });
        console.log(products)
        console.log(page * 10 < products.Pagination.total_records ? true : false)
        return {
            options: products.Collection,
            // hasMore: responseJSON.length >= 1,
            hasMore: page * 10 < products.Pagination.total_records ? true : false,
            additional: {
                page: searchQuery ? 2 : page + 1,
            },
        };
    } catch (error) {
        console.log("err : ", error);
        return {
            options: [],
            hasMore: false,
            additional: { page: 1 },
        };
    }
};
export const formatAmountWithDollarSign = (amount) => {
    // Assuming amount is a number or a string representing a numeric value
    const formattedAmount = parseFloat(amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return `${formattedAmount} /-`;
}