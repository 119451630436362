import React, { useEffect, useRef, useState } from "react"
import {
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from "reactstrap"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { updateListingAvailableByIdThunk } from "../../../features/ListingAvailable/listingAvailableThunk"

const ListingAvailableUpdate = props => {
    const uploadRef = useRef(null)
    const dispatch = useDispatch();
    const { isOpen, toggle, editId } = props
    const [data, setData] = useState({})
    const [close, setClose] = useState(true)

    const handleClose = () => {
        setClose(toggle)
    }

    const stepAddSchema = Yup.object().shape({
        listing: Yup.string().required('product listing limit is required'),
    })

    const handleAllowListing = async (values) => {
        let obj = {
            storeId: editId.store,
            addonId: editId.addOnId,
            status: 'accepted',
            listing: data.listing
        }
        console.log(obj)

        dispatch(updateListingAvailableByIdThunk(obj, handleClose))
        handleClose()
        // history.push('/all-splash-screen')

    }



    return (
        <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
        >
            <div className="modal bs-example-modal" role="dialog"></div>
            <div className="modal-content">
                <ModalHeader toggle={toggle}>Listing </ModalHeader>
                <Formik
                    initialValues={{
                        listing: "",
                    }}
                    validationSchema={stepAddSchema}
                    onSubmit={(values, actions) => {
                        console.log(values)
                        handleAllowListing(values)
                    }}
                >
                    {({ values, setValues, setFieldValue, errors, touched }) => (
                        <Form
                        >
                            <ModalBody>
                                <div className="mb-3">
                                    <Label htmlFor="formrow-firstname-Input">
                                        No. of Listings
                                    </Label>
                                    <Field
                                        type="number"
                                        className="form-control"
                                        id="listing"
                                        name="listing"
                                        onChange={e => {
                                            setData({ ...data, listing: e.target.value })
                                            setFieldValue('listing', e.target.value)
                                        }}
                                    />
                                    {errors.listing && touched.listing ? (
                                        <div className="text-danger input-error">
                                            {errors.listing}
                                        </div>
                                    ) : null}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button type="submit" color="primary" >
                                    Approve
                                </Button>
                                <Button type="button" color="secondary" onClick={toggle}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}


export default ListingAvailableUpdate