
import React, { useEffect, useState } from "react";
import {
    Row,
    Label,
    Button,
    Col,
    Modal,
    ModalBody,
} from "reactstrap"
import './Orders.css'

import { toast } from "react-toastify";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getStorePassByIdThunk, updateStorePassByIdThunk } from "../../features/StorePass/storePassThunk";
import { useDispatch } from "react-redux";
import { getOrderByIdThunk } from "../../features/Orders/orderThunk";
import moment from "moment";
import Breadcrumbs from "../../common/BreadCrums";

import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import DataTable from "react-data-table-component";
import { formatAmountWithDollarSign } from "../../common/functions";

const ViewOrder = () => {
    document.title = "View Order"
    const dispatch = useDispatch();
    const params = useParams();
    const order = useSelector(state => state.orderReducer.order);
    const [modal, setModal] = useState(false);
    const [secondModel, setSecondModel] = useState(false);
    const toggle = () => setModal(!modal);
    const orderedProductModelToggle = () => setSecondModel(!secondModel);
    const [singleProduct, setSingleProduct] = useState(null);
    const [orderedProduct, setOrderedProduct] = useState(null);

    const showProductPopup = (product) => {
        setSingleProduct(product);
        console.log(product)
        toggle();
    }

    const showOrderedProductPopup = (product) => {
        setOrderedProduct(product);
        console.log(product)
        orderedProductModelToggle();
    }

    useEffect(() => {
        console.log('calling... ')
        dispatch(getOrderByIdThunk(params.id))
    }, [params.id])

    useEffect(() => {
        console.log('product Data: ', singleProduct)
    }, [singleProduct])


    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {

                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'Product',
            selector: (row) => {
                return <button className="btn btn-sm  btn-outline-primary" onClick={() => {
                    showProductPopup(row.product);
                }}>
                    Show
                </button>
            },
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
            actualKey: 'quantity',
        },
        // {
        //     name: 'Price',
        //     selector: row => `$${row.price}`,
        //     sortable: true,
        //     actualKey: 'price',
        // },
        // {
        //     name: 'Store Discount',
        //     selector: row => row.storeDiscount,
        //     sortable: true,
        //     actualKey: 'storeDiscount',
        // },
        {
            name: 'Price',
            selector: row => `$${row.priceAfterStoreDiscount}`,
            sortable: true,
            actualKey: 'priceAfterStoreDiscount',
        },
        {
            name: 'Shipping Price',
            selector: row => `$${row.shippingPrice}`,
            sortable: true,
            actualKey: 'shippingPrice',
        },
        {
            name: 'Total Price',
            selector: row => `$${row.finalPrice}`,
            sortable: true,
            actualKey: 'finalPrice',
        },
        {
            name: 'Has Dispute',
            selector: (row) => {
                if (row.hasDispute == 1) {
                    return <span className="badge bg-danger">Yes</span>
                }
                return <span className="badge bg-success">No</span>
            },
            sortable: true,
            actualKey: 'finalPrice',
        },
        {
            name: "Detail",
            width: "200px",
            selector: row => (
                <>
                    <button type="button" className="btn btn-sm btn-outline-primary px-5" title="View Order" onClick={() => {
                        showOrderedProductPopup(row);
                    }}
                    >view</button>
                </>
            ),
        },
    ];



    return (<>
        <Breadcrumbs title="Order Detail" />
        <Row>
            <Col xl={12}>
                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={order?.items}
                                pagination={false}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>
            <Col xl={12}>
                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <table className="table table-bordered mb-0">
                                <thead>
                                    <tr align="center">
                                        <th scope="col" colSpan={2}>Order Summary #{order?.Summary?.orderId}</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td>Payment Status</td>
                                        <td>{order?.Summary?.paymentStatus}</td>
                                    </tr>
                                    <tr>
                                        <td>Created At</td>
                                        <td>{order?.Summary?.createdAt}</td>
                                    </tr>
                                    <tr>
                                        <td>PlatForm Reward Used</td>
                                        <td>{order?.Summary?.platFormReward ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                        <td>$ {order?.Summary?.orderAmountAfterPlatFormReward}</td>
                                    </tr>
                                    <tr>
                                        <td>TransactionId</td>
                                        <td>{order?.Summary?.transactionId ?? 'N/A'}</td>
                                    </tr>
                                    <tr>
                                        <td>Order Amount</td>
                                        <td>$ {order?.Summary?.orderAmount}</td>
                                    </tr>

                                </tbody>
                            </table>

                        </CCardBody>
                    </CCard>
                </CCardGroup>
            </Col>

        </Row>
        <Modal className="modal-dialog modal-xl" isOpen={modal} toggle={toggle}>
            <ModalBody>
                <div className="productCrossBtn">
                    <box-icon type='solid' name='x-circle' className="icon" onClick={() => {
                        toggle();
                    }}></box-icon>
                </div>
                {singleProduct ?
                    <div className="row g-0">
                        <div className="col-md-4 border-end">
                            <img src={process.env.REACT_APP_ASSET_URL + singleProduct?.variants[0]?.images[0]} className="img-fluid" alt="..." />
                            <div className="row mb-3 row-cols-auto g-2 justify-content-center mt-3">
                                <div className="col"><img src={process.env.REACT_APP_ASSET_URL + singleProduct?.variants[0]?.images[0]} style={{ "width": "70px" }} className="border rounded cursor-pointer" alt="" /></div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h4 className="card-title">{singleProduct.title}</h4>
                                <div className="d-flex gap-3 py-3">
                                    <div className="cursor-pointer">
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-secondary'></i>
                                    </div>
                                    <div>{singleProduct?.reviews?.length ?? 0} reviews</div>
                                    <div>{singleProduct?.likes?.length ?? 0} Likes</div>
                                    <div className="text-success"><i className='bx bxs-cart-alt align-middle'></i> {singleProduct?.orders?.length ?? 0} orders</div>
                                </div>
                                <div className="mb-3">
                                    <span className="price h4">${singleProduct?.variants[0]?.price}</span>
                                </div>
                                <p className="card-text fs-6">{singleProduct.description} Likes</p>
                                <hr />
                                <p className="card-text fs-6">Available Options</p>

                                {singleProduct.options.map((option) => {

                                    return <div className="row row-cols-auto row-cols-1 row-cols-md-3 align-items-center">
                                        <div className="col">
                                            <label className="form-label">{option.title}</label>
                                            <div>

                                                {option.values.map((val) => {

                                                    return <div className="chip chip-lg">{val}</div>

                                                })}
                                            </div>
                                        </div>
                                    </div>

                                })}
                            </div>
                        </div>
                    </div>
                    : ''}
            </ModalBody>
        </Modal>

        <Modal className="modal-dialog modal-xl" isOpen={secondModel} toggle={orderedProductModelToggle}>
            <ModalBody>
                <div className="productCrossBtn">
                    <box-icon type='solid' name='x-circle' className="icon" onClick={() => {
                        orderedProductModelToggle();
                    }}></box-icon>
                </div>
                {orderedProduct ?

                    <div class="container py-2">
                        <CCardGroup>
                            <CCard>
                                <CCardBody>
                                    <table className="table table-bordered mb-0">
                                        <thead>
                                            <tr align="center">
                                                <th scope="col" colSpan={2}>Order Summary #{order?.Summary?.orderId}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        <tr>
                                                <td>Order Status</td>
                                                <td>{orderedProduct?.status}</td>
                                            </tr>
                                            <tr>
                                                <td>Price</td>
                                                <td>{formatAmountWithDollarSign(orderedProduct?.price)}</td>
                                            </tr>

                                            <tr>
                                                <td>Platform Reward Type</td>
                                                <td>{orderedProduct?.platformRewardType}</td>
                                            </tr>

                                            <tr>
                                                <td>Platform Reward used</td>
                                                <td>{orderedProduct?.platformRewardUsed ? formatAmountWithDollarSign(orderedProduct?.platformRewardUsed) : 0}</td>
                                            </tr>

                                            <tr>
                                                <td>Store Discount</td>
                                                <td>{formatAmountWithDollarSign(orderedProduct?.storeDiscount)}</td>
                                            </tr>
                                            <tr>
                                                <td>Price After Store Discount</td>
                                                <td>{formatAmountWithDollarSign(orderedProduct?.priceAfterStoreDiscount)}</td>
                                            </tr>
                                            <tr>
                                                <td>Shipping Price</td>
                                                <td>{formatAmountWithDollarSign(orderedProduct?.shippingPrice)}</td>
                                            </tr>
                                            <tr>
                                                <td>Taxes</td>
                                                <td>{formatAmountWithDollarSign(orderedProduct?.taxes)}</td>
                                            </tr>
                                            <tr>
                                                <td>Final Price</td>
                                                <td>{formatAmountWithDollarSign(orderedProduct?.finalPrice)}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                        <h2 class="font-weight-light text-center text-muted py-3">Shipping Detail</h2>
                       
                                    <div class="row w-50 m-auto">
                                        <div class="col-auto text-center flex-column d-none d-sm-flex">
                                            <div class="row h-50">
                                                <div class="col">&nbsp;</div>
                                                <div class="col">&nbsp;</div>
                                            </div>
                                            <h5 class="m-2">
                                                <span class="badge rounded-pill bg-light border">&nbsp;</span>
                                            </h5>
                                            <div class="row h-50">
                                                <div class="col border-end">&nbsp;</div>
                                                <div class="col">&nbsp;</div>
                                            </div>
                                        </div>
                                        <div class="col py-2">
                                            <div class="card radius-15">
                                                <div class="card-body">
                                                    <p class="card-text">Product is about to ship</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                    </div>
                    : ''}
            </ModalBody>
        </Modal>
    </>);
}

export default ViewOrder;