import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    users: [],
    user: {}
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getUsers: (state, action) => {

            return {
                ...state,
                users: action.payload
            };
        },
        addUsers: (state, action) => {

            return {
                ...state,
                users: {
                    ...state.users,
                    Collection: [...state.users.Collection, action.payload]
                },
            };
        },
        getUserById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                user: action.payload,
            };
        },

        updateUserById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                users: {
                    ...state.users,
                    Collection: state.users.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        },

        deleteUserById: (state, action) => {
            return {
                ...state,
                users: {
                    ...state.users,
                    Collection: state.users.Collection.filter(item => item._id !== action.payload)
                },
            };
        }

    }
})


export const { getUsers, addUsers, getUserById, updateUserById, deleteUserById } = userSlice.actions
export default userSlice.reducer