
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Breadcrumbs from "../../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import { getOrdersAsBuyerThunk } from "../../../features/Orders/orderThunk";

const UserPlacedOrders = (props) => {
    const { userId } = props
    document.title = "UserPlacedOrders Listing"
    const [modal, setModal] = useState(false);
    const [singleOrder, setSingleOrder] = useState(null);
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();
    const orders = useSelector(state => state.orderReducer.orders);

    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        "limit": 10,
        "page": 1,
        "userId": userId

    });
    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
        });
    };


    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'order': '',
            "limit": 10,
            "page": 1
        })
    }

    const showOrderPopup = (product) => {
        setSingleOrder(product);
        console.log(product)
        toggle();
    }


    useEffect(() => {
        dispatch(getOrdersAsBuyerThunk(userId))
    }, [filterData])


    useEffect(() => {
        console.log('product Data: ', singleOrder)
    }, [singleOrder])

    useEffect(() => {
        console.log('userId :  ', userId)
    }, [])

    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                console.log('orders?.Pagination?.current_page : ', orders?.Pagination?.current_page)
                if ((orders?.Pagination?.current_page - 1) != 0) {

                    return ((orders?.Pagination?.current_page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'Items',
            selector: (row) => {
                return <button className="btn btn-sm  btn-outline-primary" onClick={() => {
                    // showOrderPopup(row);
                }}>
                    View Items
                </button>
            },
            sortable: false,
        },
        {
            name: 'OrderId',
            selector: row => row.orderId,
            sortable: false,
            actualKey: 'orderId',
        },

        {
            name: 'No. of Items',
            selector: row => row.itemsCount,
            sortable: true,
            actualKey: 'itemsCount',
        },
        
        {
            name: 'Payment Status',
            selector: row => row.paymentStatus,
            sortable: false,
            actualKey: 'paymentStatus',
        },
        {
            name: 'Total',
            selector: row => `$${row.orderAmountAfterPlatFormReward}`,
            sortable: false,
            actualKey: 'orderAmountAfterPlatFormReward',
        },
        {
            name: 'Created At',
            selector: row => row.createdAt,
            sortable: false,
            actualKey: 'createdAt',
        },
        
        {
            name: "Actions",
            width: "200px",
            selector: (row) => {
                return <button className="btn btn-sm  btn-outline-primary" onClick={() => {
                    // showOrderPopup(row);
                }}>
                    Manage
                </button>
            },
        },
    ];



    return (<>
        <Row>
            <Col xl={12}>
                <CCardGroup className="mb-2">
                    <CCard>
                        <CCardBody>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="w-100">
                                        <label>Search :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            value={filterData?.search}
                                            onChange={e => {
                                                handleFilterState('search', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1 justify-content-right">
                                    <div className="mt-3 justify-content-right">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            id="search"
                                            value="Reset"
                                            onClick={() => ResetFilters()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCardGroup>

                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={orders?.Collection}
                                pagination
                                paginationServer
                                paginationTotalRows={orders?.Pagination?.total_records}
                                paginationDefaultPage={filterData?.page}
                                rowsPerPageOptions={[10, 20, 30]}
                                paginationPerPage={filterData.limit}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                paginationComponentOptions={{ selectAllRowsItem: true, }}
                                onChangePage={(page, totalRows) => {
                                    handleFilterState('page', page)
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>
        <Modal className="modal-dialog modal-xl" isOpen={modal} toggle={toggle}>
            <ModalBody>
                <div className="productCrossBtn">
                    <box-icon type='solid' name='x-circle' className="icon" onClick={() => {
                        toggle();
                    }}></box-icon>
                </div>
                {singleOrder ?
                    <div className="row g-0">
                        <div className="col-md-4 border-end">
                            <img src={process.env.REACT_APP_ASSET_URL + singleOrder?.variants[0]?.images[0]} className="img-fluid" alt="..." />
                            <div className="row mb-3 row-cols-auto g-2 justify-content-center mt-3">
                                <div className="col"><img src={process.env.REACT_APP_ASSET_URL + singleOrder?.variants[0]?.images[0]} style={{ "width": "70px" }} className="border rounded cursor-pointer" alt="" /></div>

                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h4 className="card-title">{singleOrder.title}</h4>
                                <div className="d-flex gap-3 py-3">
                                    <div className="cursor-pointer">
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-warning'></i>
                                        <i className='bx bxs-star text-secondary'></i>
                                    </div>
                                    <div>{singleOrder?.reviews?.length ?? 0} reviews</div>
                                    <div>{singleOrder?.likes?.length ?? 0} Likes</div>
                                    <div className="text-success"><i className='bx bxs-cart-alt align-middle'></i> {singleOrder?.orders?.length ?? 0} orders</div>
                                </div>
                                <div className="mb-3">
                                    <span className="price h4">${singleOrder?.variants[0]?.price}</span>
                                </div>
                                <p className="card-text fs-6">{singleOrder.description} Likes</p>
                                <hr />
                                <p className="card-text fs-6">Available Options</p>

                                <div className="row row-cols-auto row-cols-1 row-cols-md-3 align-items-center">
                                    {singleOrder.options.map((option) => {

                                        return <div className="col-12">
                                            <label className="form-label d-block">{option.title}</label>
                                            {option.values.map((val) => {
                                                return <span className="badge bg-primary px-4 mr-2">{val}</span>
                                            })}

                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            </ModalBody>
        </Modal>
    </>);
}

export default UserPlacedOrders;