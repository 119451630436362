
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import NoImgFound from "./../../assets/No-image-found.jpg"
import './Brands.css'
import Breadcrumbs from "../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import { deleteBrandByIdThunk, getBrandThunk } from "../../features/Brand/brandThunk";
import { toastConfirm, toastConfirmDelete } from "../../common/toast";
import { toast } from "react-toastify";

const Brands = () => {
    document.title = "Brands Listing"

    const dispatch = useDispatch();
    const brands = useSelector(state => state.brandReducer.brands);
    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        'order': '',
        "limit": 10,
        "current_page": 1
    });
    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
          });
      };
    const deleteBrandById = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to delete this?")).fire();
        if (response.isConfirmed) {
            dispatch(deleteBrandByIdThunk(id)).unwrap()
                .then(() => {

                })
                .catch((error) => {
                    toast.error(error.message /* error.message */, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    }

    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'order': '',
            "limit": 10,
            "current_page": 1
        })
    }

    useEffect(() => {
        dispatch(getBrandThunk(filterData))
    }, [filterData])


    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                if ((brands?.Pagination?.current_page - 1) != 0) {

                    return ((brands?.Pagination?.current_page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            actualKey: 'name',
        },
        {
            name: 'Image',
            selector: (row) => {
                return <img className="tableImage" src={row.image != '' ? `${process.env.REACT_APP_ASSET_URL}${row.image}` : NoImgFound} />
            }
        },
        {
            name: "Actions",
            width: "200px",
            selector: row => (
                <>
                    <Link to={`brands/${row._id}/edit`} >
                        <box-icon type='solid' size='sm' color='#008cff' className="px-2" name='edit' title="Edit Brand"></box-icon>
                    </Link>
                    <box-icon name='trash' type='solid' size='sm' color='#008cff' className="px-2 cursor-pointer"
                        onClick={() => {
                            // alert(row._id)
                            deleteBrandById(row._id);
                        }}>

                    </box-icon>
                </>
            ),
        },
    ];



    return (<>
        <Breadcrumbs title="Brands Listing" />
        <Row>
            <Col xl={12}>
                <div className="mb-2">
                    <Link to={'/brands/add'} className="btn btn-primary w-md">Add +</Link>
                </div>
                <CCardGroup className="mb-2">
                    <CCard>
                        <CCardBody>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="w-100">
                                        <label>Search :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            value={filterData?.search}
                                            onChange={e => {
                                                handleFilterState('search', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1 justify-content-right">
                                    <div className="mt-3 justify-content-right">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            id="search"
                                            value="Reset"
                                            onClick={() => ResetFilters()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCardGroup>

                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={brands?.Collection}
                                pagination
                                paginationServer
                                paginationTotalRows={brands?.Pgaination?.total}
                                paginationDefaultPage={filterData?.current_page}
                                rowsPerPageOptions={[10, 20, 30]}
                                paginationPerPage={filterData.limit}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                paginationComponentOptions={{ selectAllRowsItem: true, }}
                                onChangePage={(page, totalRows) => {
                                    handleFilterState('current_page', page)
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>
    </>);
}

export default Brands;