import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    storePasses: [],
    storePass: {}
}


export const storePassSlice = createSlice({
    name: 'storePass',
    initialState,
    reducers: {
        getStorePasses: (state, action) => {

            return {
                ...state,
                storePasses: action.payload
            };
        },
        addStorePasses: (state, action) => {

            return {
                ...state,
                storePasses: {
                    ...state.storePasses,
                    Collection: [...state.storePasses.Collection, action.payload]
                },
            };
        },
        getStorePassById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                storePass: action.payload,
            };
        },

        updateStorePassById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                storePasses: {
                    ...state.storePasses,
                    Collection: state.storePasses.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        },

        deleteStorePassById: (state, action) => {
            return {
                ...state,
                storePasses: {
                    ...state.storePasses,
                    Collection: state.storePasses.Collection.filter(item => item._id !== action.payload)
                },
            };
        }

    }
})


export const { getStorePasses, addStorePasses, getStorePassById, updateStorePassById, deleteStorePassById } = storePassSlice.actions
export default storePassSlice.reducer