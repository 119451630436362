import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Card, CardBody, CardText, CardTitle, Table } from "reactstrap";
import { getStoreSubscriptionPlansThunk } from "../../../features/OtherApi/ApiThunk";
import NoImgFound from "./../../../assets/No-image-found.jpg"
const UserStore = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const [store, setStore] = useState(null);
    useEffect(() => {
        dispatch(getStoreSubscriptionPlansThunk(params.id)).unwrap().
            then((res) => {
                setStore(res);
                console.log(res)
            }).catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            });
    }, [])

    return (
        <>
            <div className="col-sm-12 col-md-12">
                <p className="h3 p-3  my-3  text-center">Store Subscription Plans</p>
            </div>
            {
                store?.length ? store.map((plan, index) => {
                    return (<>
                        <div className="col-sm-12 col-md-4">
                            <Card className="my-2" color="primary" outline >
                                <img
                                    className="w-50 m-auto"
                                    src={ `${process.env.REACT_APP_ASSET_URL}${plan?.image}` }
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = NoImgFound;
                                    }}

                                />
                                <CardBody>
                                    <CardTitle tag="h5" className="text-capitalize text-center font-weight-bold" >
                                        {plan?.name}
                                    </CardTitle>
                                    <CardText>
                                        <label htmlFor="Descipription"><strong>Description:</strong></label><br />
                                        {plan?.description}
                                    </CardText>

                                    <Table hover>
                                        <tbody>
                                            <tr>
                                                <td>Plan Price</td>
                                                <td> ${plan?.price}</td>
                                            </tr>
                                            <tr>
                                                <td>Store Discount</td>
                                                <td> {plan?.storeDiscount}%</td>
                                            </tr>
                                            <tr>
                                                <td>Status</td>
                                                <td> {plan?.status}</td>
                                            </tr>
                                            <tr>
                                                <td>GiveAway Items</td>
                                                <td> {plan?.giveawayItems.length}</td>
                                            </tr>
                                            <tr>
                                                <td>Active Subscribers</td>
                                                <td> {plan?.subscribersCount}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </div>
                    </>)
                }) : <div className="card">
                    <div className="card-body text-center" style={{'padding' :'24px'}}>
                       <p className="text-center"> There are no records to display</p>

                    </div>
                </div>
            }
        </>
    )
}


export default UserStore;
