import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    featuredContent: [],
}


export const featuredContentSlice = createSlice({
    name: 'featuredContent',
    initialState,
    reducers: {
        getFeaturedContent: (state, action) => {

            return {
                ...state,
                featuredContent: action.payload
            };
        },
        addFeaturedContent: (state, action) => {

            return {
                ...state,
                featuredContent: {
                    ...state.featuredContent,
                    Collection: [...state.featuredContent.Collection, action.payload]
                },
            };
        },
       
        updateFeaturedContentById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                featuredContent: {
                    ...state.featuredContent,
                    Collection: state.featuredContent.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        },
    }
})


export const { getFeaturedContent, addFeaturedContent, updateFeaturedContentById, deleteFeaturedContentById } = featuredContentSlice.actions
export default featuredContentSlice.reducer