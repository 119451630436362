import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    brands: [],
    brand: {},
    brandProducts:[]
}


export const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        getBrands: (state, action) => {

            return {
                ...state,
                brands: action.payload
            };
        },
        getBrandProducts: (state, action) => {
            console.log('action.payload : ' ,action.payload)
            if(state.brandProducts?.Collection){
                return {
                    ...state,
                    brandProducts: {
                        ...state.brandProducts,
                        Collection: [...state.brandProducts.Collection,...action.payload.Collection],
                        Pagination: {...action.payload.Pagination }
                    },
                };
            }
            return {
                ...state,
                brandProducts: action.payload
            };
        },
        addBrands: (state, action) => {

            return {
                ...state,
                brands: {
                    ...state.brands,
                    Collection: [...state.brands.Collection, action.payload]
                },
            };
        },
        getBrandById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                brand: action.payload,
            };
        },

        updateBrandById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                brands: {
                    ...state.brands,
                    Collection: state.brands.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        },

        deleteBrandById: (state, action) => {
            return {
                ...state,
                brands: {
                    ...state.brands,
                    Collection: state.brands.Collection.filter(item => item._id !== action.payload)
                },
            };
        }

    }
})


export const { getBrands, addBrands, getBrandProducts, getBrandById, updateBrandById, deleteBrandById } = brandSlice.actions
export default brandSlice.reducer