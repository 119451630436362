import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import { addListingAvailable, deleteListingAvailableById, getListingAvailable, getListingAvailableById, updateListingAvailableById } from "./listingAvailableSlice";


export const getListingAvailableThunk = createAsyncThunk('listingAvailable', async (filters, thunkApi) => {
  try {
    const response = await axiosApi.post(url.GET_LISTING_AVAILABLE, filters) 
    thunkApi.dispatch(getListingAvailable(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getListingAvailableByIdThunk = createAsyncThunk('listingAvailable/edit', async (id, thunkApi) => {
  try {
    
    const response = await axiosApi.get(`${url.GET_LISTING_AVAILABLE}/${id}`)  
    thunkApi.dispatch(getListingAvailableById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const updateListingAvailableByIdThunk = createAsyncThunk('listingAvailable/update', async (data, thunkApi) => {
  try {

    const response = await axiosApi.post(`${url.UPDATE_LISTING_AVAILABLE}`, data );  


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(getListingAvailable(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const deleteListingAvailableByIdThunk = createAsyncThunk('listingAvailable/delete', async (id, thunkApi) => {
  try {

    const response = await axiosApi.delete(`${url.DELETE_CATEGORY}/${id}`);  


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(deleteListingAvailableById(id))
    return thunkApi.fulfillWithValue(id)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const addListingAvailableThunk = createAsyncThunk('listingAvailable/add', async ({ data }, thunkApi) => {
  try {
    let image = []
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }
    console.log('image ', image)

    const response = await axiosApi.post(url.ADD_LISTING_AVAILABLE, { name: data.name, image });  
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(addListingAvailable(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});