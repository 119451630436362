import React, { useEffect, useState } from "react"
import {
    Button,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"
import { LoadUsers } from "../../../../common/functions"
import SelectAsyncPaginate from "../../../../common/SelectAsyncPaginate"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { addFeaturedContentThunk } from "../../../../features/FeaturedContent/featuredContentThunk"
import { toast } from "react-toastify"

const AddSeller = props => {
    const dispatch = useDispatch();
    const { isOpen, toggle } = props
    const [data, setData] = useState({
        startDate: null,
        expiryDate: null,
        seller: {},
        status: false,
    })
    const [close, setClose] = useState(true)

    const handleClose = () => {
        setClose(toggle)
    }
    

    const stepAddSchema = Yup.object().shape({
        startDate: Yup.date().required("This field is required"),
        expiryDate: Yup.date().required("This field is required"),
        status: Yup.string().required("This field is required"),
    })

    const handleFeatureSeller = async (values) => {

        dispatch(addFeaturedContentThunk({data, handleClose,filters: { type: 'seller' }})).unwrap()
            .then(() => {
                setData({
                    startDate: null,
                    expiryDate: null,
                    seller: {},
                    status: false,
                })
            }).catch((error) => {
                toast.error(error.message /* error.message */, {
                    position: toast.POSITION.TOP_RIGHT
                });
            })

    }
    useEffect(() => {
        setData({
            startDate: null,
            expiryDate: null,
            seller: {},
            status: false,
        })
    }, [isOpen])


    return (
        <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
        >
            <div className="modal bs-example-modal" role="dialog"></div>
            <div className="modal-content">
                <ModalHeader toggle={toggle}>Feature Seller </ModalHeader>
                <Formik
                    initialValues={{ ...data }}
                    validationSchema={stepAddSchema}
                    enableReinitialize
                    onSubmit={(values, actions) => {
                        console.log(values)
                        handleFeatureSeller(values)
                    }}
                >
                    {({ values, setValues, setFieldValue, errors, touched }) => (
                        <Form
                        >
                            <ModalBody>
                                <div className="mb-3" >
                                    <label>Select Seller:</label>
                                    <SelectAsyncPaginate
                                        name="seller"
                                        // regionName={region.value}
                                        loadOptions={LoadUsers}
                                        label={"Sellers"}
                                        isMulti={false}
                                        value={data?.seller}
                                        onChange={(seller) => {
                                            setData({ ...data, seller });
                                            console.log('seller : ', seller)
                                        }}
                                    />
                                </div>
                                <div className="mb-3 d-flex flex-column">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Starts On
                                    </Label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            ClassName="form-control dateInputBox"
                                            name="startDate"
                                            type="date"
                                            value={data?.startDate}
                                            onChange={newValue => {
                                                setData({ ...data, startDate: newValue.$d })
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {errors.startDate && touched.startDate ? (
                                        <div className="text-danger input-error">
                                            {errors.startDate}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3 d-flex flex-column">
                                    <Label htmlFor="formrow-firstname-Input">
                                        Ends On
                                    </Label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            ClassName="form-control dateInputBox"
                                            name="expiryDate"
                                            type="date"
                                            value={data?.expiryDate}

                                            onChange={newValue => {
                                                setData({ ...data, expiryDate: newValue.$d })
                                                console.log(data)
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {errors.expiryDate && touched.expiryDate ? (
                                        <div className="text-danger input-error">
                                            {errors.expiryDate}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mb-3">
                                    <Label htmlFor="Type">Status</Label>
                                    <Field name="status" className="form-control"
                                        onChange={e => {
                                            setData({ ...data, status: e.target.value })
                                        }}>
                                        {({ field, form }) => (
                                            <div className="sub-category-select">
                                                <select id="type" className="form-control input-color " name="isActive" onChange={e => {
                                                    setData({ ...data, status: e.target.value })
                                                }}>
                                                    <option value="">Choose</option>
                                                    <option value="0">Active</option>
                                                    <option value="1">Deactive</option>
                                                </select>
                                                {form.touched.status &&
                                                    form.errors.status ? (
                                                    <div className="input-error text-danger">
                                                        {form.errors.status}
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </Field>

                                </div>

                            </ModalBody>
                            <ModalFooter>
                                <Button type="submit" color="primary" >
                                    Save Changes
                                </Button>
                                <Button type="button" color="secondary" onClick={toggle}>
                                    Close
                                </Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}


export default AddSeller