import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import { addSubCategories, deleteSubCategoryById, getSubCategories, getSubCategoryById, updateSubCategoryById } from "./subCategorySlice";


export const getSubCategoryThunk = createAsyncThunk('subCategories', async (id, thunkApi) => {
  try {
    // thunkApi.dispatch()
    console.log('parent isd  : ' , id)
    const response = await axiosApi.get(id + url.GET_SUB_CATEGORIES)  //AuthLogin(data);

    thunkApi.dispatch(getSubCategories(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getSubCategoryByIdThunk = createAsyncThunk('subCategories/edit', async (id, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_SUB_CATEGORIES}/${id}`)  //AuthLogin(data);

    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const updateSubCategoryByIdThunk = createAsyncThunk('subCategories/update', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    let image = data.image;
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }
    console.log('image ', image)

    const response = await axiosApi.post(`${url.UPDATE_SUB_CATEGORIES}/${data._id}/edit`, { name: data.name, image });  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(updateSubCategoryById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const deleteSubCategoryByIdThunk = createAsyncThunk('subCategories/delete', async (id, thunkApi) => {
  try {

    const response = await axiosApi.delete(`${url.DELETE_CATEGORY}/${id}`);  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(deleteSubCategoryById(id))
    return thunkApi.fulfillWithValue(id)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const addSubCategoryThunk = createAsyncThunk('subCategories/add', async ( data , thunkApi) => {
  try {
    let image = []
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }
    console.log('image ', image)

    const response = await axiosApi.post(url.ADD_CATEGORIES, { name: data.name, image ,parentId :data.parentId});  //AuthLogin(data);
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(addSubCategories(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});