import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import { addOrders, deleteOrderById, getOrders, getOrderById, updateOrderById } from "./orderSlice";


export const getOrdersAsBuyerThunk = createAsyncThunk('user/getOrdersAsBuyer', async (id, thunkApi) => {
  try {
    const response = await axiosApi.get(`${url.GET_USER_ORDERS_AS_BUYER}/${id}/ordersAsBuyer`);
    return thunkApi.dispatch(getOrders(response.data))

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});



export const getOrdersThunk = createAsyncThunk('user/getOrder', async (id, thunkApi) => {
  try {
    const response = await axiosApi.get(url.GET_ORDERS);
    return thunkApi.dispatch(getOrders(response.data))

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getOrderByIdThunk = createAsyncThunk('orders/edit', async (id, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_ORDERS}/${id}`)  //AuthLogin(data);
    thunkApi.dispatch(getOrderById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

// export const updateOrderByIdThunk = createAsyncThunk('orders/update', async (data, thunkApi) => {
//   try {
//     // thunkApi.dispatch()
//     let image = data.image;
//     if (data.file) {
//       image = await thunkApi.dispatch(uploadImage(data)).unwrap();
//     }
//     console.log('image ', image)

//     const response = await axiosApi.post(`${url.UPDATE_ORDERS}/${data._id}/edit`, { name: data.name,status: data.status, image });  //AuthLogin(data);


//     toast.success(response.message /* error.message */, {
//       position: toast.POSITION.TOP_RIGHT
//     });
//     thunkApi.dispatch(updateOrderById(response.data))
//     return thunkApi.fulfillWithValue(response.data)

//   } catch (error) {
//     toast.error(error.message /* error.message */, {
//       position: toast.POSITION.TOP_RIGHT
//     });
//     return thunkApi.rejectWithValue(error.message)
//   }
// });

// export const deleteOrderByIdThunk = createAsyncThunk('orders/delete', async (id, thunkApi) => {
//   try {

//     const response = await axiosApi.delete(`${url.DELETE_ORDER}/${id}`);  //AuthLogin(data);


//     toast.success(response.message /* error.message */, {
//       position: toast.POSITION.TOP_RIGHT
//     });
//     thunkApi.dispatch(deleteOrderById(id))
//     return thunkApi.fulfillWithValue(id)

//   } catch (error) {
//     toast.error(error.message /* error.message */, {
//       position: toast.POSITION.TOP_RIGHT
//     });
//     return thunkApi.rejectWithValue(error.message)
//   }
// });

// export const addOrderThunk = createAsyncThunk('orders/add', async ( data , thunkApi) => {
//   try {
//     let image = []
//     if (data.file) {
//       image = await thunkApi.dispatch(uploadImage(data)).unwrap();
//     }else{
//       throw new Error('image is required ')
//     }
//     console.log('image ', image)

//     const response = await axiosApi.post(url.ADD_ORDERS, { name: data.name, status:data.status ,image });  //AuthLogin(data);
//     toast.success(response.message, {
//       position: toast.POSITION.TOP_RIGHT
//     });
//     thunkApi.dispatch(addOrders(response.data))
//     return thunkApi.fulfillWithValue(response.data)

//   } catch (error) {
//     toast.error(error.message, {
//       position: toast.POSITION.TOP_RIGHT
//     });
//     return thunkApi.rejectWithValue(error.message)
//   }
// });