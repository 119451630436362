
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Modal, ModalBody,  Row } from "reactstrap";
import Breadcrumbs from "../../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import {getProductThunk } from "../../../features/Product/productThunk";

const UserProducts = (props) => {
    const {userId} = props
    document.title = "UserProducts Listing"
    const [modal, setModal] = useState(false);
    const [singleProduct, setSingleProduct] = useState(null);
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();
    const products = useSelector(state => state.productReducer.products);

    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        "limit": 10,
        "page": 1,
        "userId" : userId

    });
    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
        });
    };


    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'order': '',
            "limit": 10,
            "page": 1
        })
    }

    const showProductPopup = (product) => {
        setSingleProduct(product);
        console.log(product)
        toggle();
    }

    useEffect(() => {
        dispatch(getProductThunk(filterData))
    }, [filterData])


    useEffect(() => {
        console.log('product Data: ', singleProduct)
    }, [singleProduct])

    useEffect(()=>{
        console.log('userId :  ' , userId)
    },[])

    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                console.log('products?.Pagination?.current_page : ', products?.Pagination?.current_page)
                if ((products?.Pagination?.current_page - 1) != 0) {

                    return ((products?.Pagination?.current_page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'Product',
            selector: (row) => {
                return <button className="btn btn-sm  btn-outline-primary" onClick={() => {
                    showProductPopup(row);
                }}>
                    Show
                </button>
            },
            sortable: false,
        },
        {
            name: 'Name',
            selector: row => row.title,
            sortable: false,
            actualKey: 'title',
        },

        {
            name: 'Brand',
            selector: (row) => {
                if (row.brand?.name)
                    return <Link to={`brands/${row.brand?._id}/view`} className="actionIcons">
                        {row.brand.name}
                    </Link>
            },
            sortable: true,
            actualKey: 'brand.name',
        },
        {
            name: 'Category',
            selector: row => row.category?.name,
            sortable: true,
            actualKey: 'category.name',
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: false,
            actualKey: 'type',
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: false,
            actualKey: 'status',
        },
        {
            name: 'Created At',
            selector: row => row.createdAt,
            sortable: false,
            actualKey: 'createdAt',
        },
        
    ];



    return (<>
        <Row>
            <Col xl={12}>
                <CCardGroup className="mb-2">
                    <CCard>
                        <CCardBody>
                            <div className="row">
                                <div className="col-md-6 ">
                                    <div className="w-100">
                                        <label>Search :</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            value={filterData?.search}
                                            onChange={e => {
                                                handleFilterState('search', e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 mt-1 justify-content-right">
                                    <div className="mt-3 justify-content-right">
                                        <input
                                            type="button"
                                            className="btn btn-primary"
                                            id="search"
                                            value="Reset"
                                            onClick={() => ResetFilters()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CCardBody>
                    </CCard>
                </CCardGroup>

                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={products?.Collections}
                                pagination
                                paginationServer
                                paginationTotalRows={products?.Pagination?.total_records}
                                paginationDefaultPage={filterData?.page}
                                rowsPerPageOptions={[10, 20, 30]}
                                paginationPerPage={filterData.limit}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                paginationComponentOptions={{ selectAllRowsItem: true, }}
                                onChangePage={(page, totalRows) => {
                                    handleFilterState('page', page)
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>
    </>);
}

export default UserProducts;