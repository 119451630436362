import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    listingAvailable: [],
    listing: {}
}


export const listingAvailableSlice = createSlice({
    name: 'listing',
    initialState,
    reducers: {
        getListingAvailable: (state, action) => {

            return {
                ...state,
                listingAvailable: action.payload
            };
        },
        addListingAvailable: (state, action) => {

            return {
                ...state,
                listingAvailable: {
                    ...state.listingAvailable,
                    Collection: [...state.listingAvailable.Collection, action.payload]
                },
            };
        },
        getListingAvailableById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                listing: action.payload,
            };
        },

        updateListingAvailableById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                listingAvailable: {
                    ...state.listingAvailable,
                    Collection: state.listingAvailable.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        },

        deleteListingAvailableById: (state, action) => {
            return {
                ...state,
                listingAvailable: {
                    ...state.listingAvailable,
                    Collection: state.listingAvailable.Collection.filter(item => item._id !== action.payload)
                },
            };
        }

    }
})


export const { getListingAvailable, addListingAvailable, getListingAvailableById, updateListingAvailableById, deleteListingAvailableById } = listingAvailableSlice.actions
export default listingAvailableSlice.reducer