import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";
import { uploadImage } from "../Common/ImageUploadThunk";
import { addProducts, deleteProductById, getProducts, getProductById, updateProductById, changeRecommended } from "./productSlice";


export const getProductThunk = createAsyncThunk('products', async (filters, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.post(url.GET_PRODUCTS, filters)  //AuthLogin(data);

    thunkApi.dispatch(getProducts(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getProductByIdThunk = createAsyncThunk('products/edit', async (id, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_PRODUCTS}/${id}`)  //AuthLogin(data);
    thunkApi.dispatch(getProductById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});


export const updateProductByIdThunk = createAsyncThunk('products/update', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    let image = data.image;
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }
    console.log('image ', image)

    const response = await axiosApi.post(`${url.UPDATE_PRODUCTS}/${data._id}/edit`, { name: data.name,status: data.status, image });  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(updateProductById(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const changeRecommendedThunk = createAsyncThunk('products/recommended', async (data, thunkApi) => {
  try {
    console.log("data",data);
    // thunkApi.dispatch()

    const response = await axiosApi.post( `${url.UPDATE_PRODUCTS}/${data._id}/recommended`, { isRecommended: data.isRecommended } );  //AuthLogin(data);
    thunkApi.dispatch(changeRecommended(data))

    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });

    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const deleteProductByIdThunk = createAsyncThunk('products/delete', async (id, thunkApi) => {
  try {

    const response = await axiosApi.delete(`${url.DELETE_PRODUCT}/${id}`);  //AuthLogin(data);


    toast.success(response.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(deleteProductById(id))
    return thunkApi.fulfillWithValue(id)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const addProductThunk = createAsyncThunk('products/add', async ( data , thunkApi) => {
  try {
    let image = []
    if (data.file) {
      image = await thunkApi.dispatch(uploadImage(data)).unwrap();
    }else{
      throw new Error('image is required ')
    }
    console.log('image ', image)

    const response = await axiosApi.post(url.ADD_PRODUCTS, { name: data.name, status:data.status ,image });  //AuthLogin(data);
    toast.success(response.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    thunkApi.dispatch(addProducts(response.data))
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});