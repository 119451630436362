import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";

const SelectAsyncPaginate = (props) => {

  const onChange = (option) => {
    console.log("option", option);
    if (typeof props.onChange === "function") {
      props.onChange(option);
    }
  };

  return (
    <AsyncPaginate   
      // key={JSON.stringify(regionName)}
      value={props.value || ""}
      placeholder={props.label}
      getOptionValue={(option) => option.name}
      getOptionLabel={(option) => option.name}
      onChange={onChange}
      isSearchable={false}
      additional={{
        page: 1,
      }}
      {...props}
    />
  );
};
export default SelectAsyncPaginate;