
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardBody, CardImg, CardTitle, Col, Row } from "reactstrap";
import NoImgFound from "./../../assets/No-image-found.jpg"
import plus from "./../../assets/plus.png"
import '../categories/categories.css'
import { CButton } from "@coreui/react";
import { toastConfirm } from "../../common/toast";
import Breadcrumbs from "../../common/BreadCrums";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { deleteSubCategoryByIdThunk, getSubCategoryThunk } from "../../features/SubCategories/subCategoryThunk";
import SubCategroyAdd from "./models/SubCategoryAdd";
import SubCategoryEdit from "./models/SubCategoryEdit";
import { getCategoryByIdThunk } from "../../features/Category/categoryThunk";

const SubCategories = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    document.title = "Sub Categories Listing"

    const subCategories = useSelector(state => state.subCategoryReducer.subCategories);
    const category = useSelector(state => state.categoryReducer.category);

    const [addModal, setAddModal] = useState(false)
    const [editId, setEditId] = useState('')
    const toggleAddModal = () => setAddModal(!addModal)

    const [editModal, setEditModal] = useState(false)
    const toggleEditModal = () => setEditModal(!editModal)


    useEffect(() => {
        dispatch(getCategoryByIdThunk(params?.id))
        dispatch(getSubCategoryThunk(params?.id))
    }, [params?.id])



    const deleteCategroryId = async (id) => {
        const response = await (await toastConfirm("Are you sure you want to delete this?")).fire();
        if (response.isConfirmed) {
            dispatch(deleteSubCategoryByIdThunk(id)).unwrap()
                .then(() => {

                })
                .catch((error) => {
                    toast.error(error.message /* error.message */, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                })
        }
    }

    return (<>
        {/* Render Breadcrumbs */}
        <SubCategroyAdd isOpen={addModal} toggle={toggleAddModal} parentId={params?.id} />
        <SubCategoryEdit isOpen={editModal} toggle={toggleEditModal} editId={editId} />
        <Breadcrumbs curent={category} parents={subCategories.parentCategory} title="SubCategories" />
        <Row className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 product-grid categoryPage">
            {subCategories?.Collection?.length ? subCategories?.Collection.map((item, index) => {
                return (
                    <>
                        <Col mg={6} xl={2} key={index + 1} className="normalCard position-relative">

                            <Card className="categories-card">

                                <CardBody>
                                    <CardTitle tag="h5" className="text-center">
                                        {item.name}
                                    </CardTitle>
                                    <CardImg
                                        top
                                        className="img-fluid img"
                                        src={item.image != '' ? `${process.env.REACT_APP_ASSET_URL}${item.image}` : NoImgFound}
                                        alt={item.name}
                                    />
                                </CardBody>

                            </Card>

                            <div className="edit">
                                <CButton
                                    variant="text"
                                    className="newEditIcon p-0"
                                    onClick={() => {
                                        toggleEditModal();
                                        setEditId(item._id)
                                    }}
                                >
                                    <box-icon type="solid" name="edit" title="Edit"></box-icon>
                                </CButton>
                                <CButton
                                    variant="text"
                                    className="newDeleteIcon p-0"
                                    onClick={() => deleteCategroryId(item._id)}
                                >
                                    <box-icon type="solid" name="trash" title="Delete"></box-icon>

                                </CButton>
                                <CButton
                                    variant="text"
                                    className="newDeleteIcon p-0"
                                    onClick={() =>
                                        history.push(`/subcategories/${item._id}`)}
                                >
                                    <box-icon name='list-minus' title="Sub SubCategories"></box-icon>

                                </CButton>
                            </div>
                        </Col>
                    </>
                )
            }) : ''}
            <Col mg={6} xl={2} className="add">
                <Card
                    className="categories-card  d-flex align-items-center justify-content-center"
                    onClick={() => {
                        toggleAddModal()

                    }}
                >
                    <CardImg
                        top
                        className="img-fluid addImg"
                        src={plus}
                        alt="Add Category"
                    />
                </Card>
            </Col>
        </Row>
    </>);
}

export default SubCategories;