import React from "react";


const UserLiveStreams = () => {
    return (
        <>
            <div className="row  ">
                <div className="col">
                    <div className="card bg-primary">
                        <div className="card-body">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item bg-transparent text-white"><i className="bx bx-cart-alt font-18 align-middle me-1"></i> Cras justo odio</li>
                                <li className="list-group-item bg-transparent text-white"><i className="bx bx-paper-plane font-18 align-middle me-1"></i> Dapibus ac facilisis in</li>
                                <li className="list-group-item bg-transparent text-white"><i className="bx bx bx-plug font-18 align-middle me-1"></i>Morbi leo risus</li>
                                <li className="list-group-item bg-transparent text-white"><i className="bx bx-hourglass font-18 align-middle me-1"></i>Porta ac consectetur ac</li>
                                <li className="list-group-item bg-transparent text-white"><i className="bx bx bx-support font-18 align-middle me-1"></i>Vestibulum at eros</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default UserLiveStreams;
