
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import NoImgFound from "./../../assets/No-image-found.jpg"
import './ListingAvailable.css'
import Breadcrumbs from "../../common/BreadCrums";
import DataTable from 'react-data-table-component';
import { Link } from "react-router-dom";
import { CCard, CCardBody, CCardGroup } from "@coreui/react";
import { getListingAvailableThunk } from "../../features/ListingAvailable/listingAvailableThunk";
import ListingAvailableUpdate from "./models/ListingAvailableUpdate";
import { CButton } from "@coreui/react";

const ListingAvailable = () => {
    document.title = "Listing Request"

    const dispatch = useDispatch();
    const listingAvailable = useSelector(state => state.listingAvailableReducer.listingAvailable);
    const [filterData, setFilterData] = useState({
        'search': '',
        'sortBy': '',
        'order': '',
        'order': '',
        "limit": 10,
        "current_page": 1
    });
    const [editId, setEditId] = useState('')
    const [editModal, setEditModal] = useState(false)
    const toggleEditModal = () => setEditModal(!editModal)

    const handleFilterState = (key, value) => setFilterData((prev) => ({ ...prev, [key]: value }))

    const handleMultiChange = (data) => setFilterData((prev) => ({ ...prev, ...data }))

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        setFilterData({
            limit: newRowsPerPage
        });
    };

    const ResetFilters = () => {
        handleMultiChange({
            'search': '',
            'sortBy': '',
            'order': '',
            'order': '',
            "limit": 10,
            "current_page": 1
        })
    }

    useEffect(() => {
        dispatch(getListingAvailableThunk(filterData))
    }, [filterData])


    const columns = [
        {
            name: "Sr. no",
            selector: (row, index) => {
                if ((listingAvailable?.Pagination?.current_page - 1) != 0) {

                    return ((listingAvailable?.Pagination?.current_page - 1) * filterData.limit) + (index + 1)
                }
                return index + 1
            },
            sortable: true,
            width: "100px"
        },
        {
            name: 'Name',
            selector: row => row.userDetail.name,
            sortable: true,
            actualKey: 'name',
        },
        {
            name: 'Email',
            selector: row => row.userDetail.email,
            sortable: true,
            actualKey: 'email',
        },
        {
            name: 'Image',
            selector: (row) => {
                return <img className="tableImage" src={row.userDetail.image != '' ? `${process.env.REACT_APP_ASSET_URL}${row.userDetail.image}` : NoImgFound} />
            }
        },
        {
            name: 'Total Products ',
            selector: row => row.storedetails?.product_count,
            sortable: true,
            actualKey: 'storedetails.product_count',
        },
        {
            name: "Actions",
            width: "200px",
            selector: row => (
                <div className="d-flex">
                    <CButton
                        variant="text"
                        className="btn btn-sm  btn-outline-primary"
                        onClick={() => {
                            toggleEditModal();
                            setEditId(row)
                        }}
                    >
                        Approve
                    </CButton>
                </div>
            ),
        },
    ];



    return (<>
        <Breadcrumbs title="Listing Request" />
        <ListingAvailableUpdate isOpen={editModal} toggle={toggleEditModal} editId={editId} />
        <Row>
            <Col xl={12}>

                <CCardGroup>
                    <CCard>
                        <CCardBody>
                            <DataTable
                                columns={columns}
                                data={listingAvailable?.Collection}
                                pagination
                                paginationServer
                                paginationTotalRows={listingAvailable?.Pgaination?.total}
                                paginationDefaultPage={filterData?.current_page}
                                rowsPerPageOptions={[10, 20, 30]}
                                paginationPerPage={filterData.limit}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                paginationComponentOptions={{ selectAllRowsItem: true, }}
                                onChangePage={(page, totalRows) => {
                                    handleFilterState('current_page', page)
                                }}
                            />
                        </CCardBody>
                    </CCard>
                </CCardGroup>

            </Col>

        </Row>
    </>);
}

export default ListingAvailable;