
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { axiosApi } from "../../helper/api_helper";
import * as url from "../../helper/url_helper";


export const getTrendingHashTagsThunk = createAsyncThunk('trending/hashTags', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_TRENDING_HASHTAGS}/?limit=30`,)  //AuthLogin(data);

    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getTrendingProfilesThunk = createAsyncThunk('trending/profiles', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_TRENDING_PROFILES}/?limit=30`,)  //AuthLogin(data);

    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getBrandsListingThunk = createAsyncThunk('brandsListing', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(`${url.GET_BRANDS_LISTING}/?limit=30`,)  //AuthLogin(data);

    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getReferralProgramSettingThunk = createAsyncThunk('setting/referralProgram', async (data, thunkApi) => {
  try {
    // thunkApi.dispatch()
    const response = await axiosApi.get(url.GET_REFERRAL_PROGRAM_SETTING);
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const updateReferralProgramSettingThunk = createAsyncThunk('setting/referralProgram', async (data, thunkApi) => {
  try {
    const response = await axiosApi.post(url.GET_REFERRAL_PROGRAM_SETTING, {
      referralType: data.referralType,
      rewardType: data.rewardType,
      rewardValue: data.rewardValue,
      expireInDays: data.expireInDays,
      status: data.status,
    });
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message /* error.message */, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});

export const getStoreSubscriptionPlansThunk = createAsyncThunk('store/subscriptionsPlans', async (id, thunkApi) => {
  try {
    const response = await axiosApi.get(`${url.GET_STORE_SUBSCRIPTION_PLANS}?id=${id}`);
    return thunkApi.fulfillWithValue(response.data)

  } catch (error) {
    toast.error(error.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    return thunkApi.rejectWithValue(error.message)
  }
});




