import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/Auth/authSlice'
import loadingReducer from '../features/Loader/loaderSlice'
import categoryReducer from '../features/Category/categorySlice'
import subCategoryReducer from '../features/SubCategories/subCategorySlice'
import userReducer from '../features/User/userSlice'
import listingAvailableReducer from '../features/ListingAvailable/listingAvailableSlice'
import brandReducer from '../features/Brand/brandSlice'
import  storePassReducer from '../features/StorePass/storePassSlice'
import  inAppSubscriptionReducer from '../features/InAppSubscriptions/inAppSubscriptionSlice'
import  productReducer from '../features/Product/productSlice'
import featuredContentReducer from '../features/FeaturedContent/featureContentSlice'
import orderReducer from '../features/Orders/orderSlice'

const store = configureStore({
    reducer: combineReducers({
        authReducer: authReducer,
        loadingReducer: loadingReducer,
        categoryReducer: categoryReducer,
        subCategoryReducer: subCategoryReducer,
        userReducer: userReducer,
        listingAvailableReducer: listingAvailableReducer,
        brandReducer: brandReducer,
        storePassReducer: storePassReducer,
        inAppSubscriptionReducer: inAppSubscriptionReducer,
        productReducer: productReducer,
        featuredContentReducer: featuredContentReducer,
        orderReducer: orderReducer
    })
});

export default store;

