import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    categories: [],
    category: {}
}


export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        getCategories: (state, action) => {

            return {
                ...state,
                categories: action.payload
            };
        },
        addCategories: (state, action) => {

            return {
                ...state,
                categories: {
                    ...state.categories,
                    Collection: [...state.categories.Collection, action.payload]
                },
            };
        },
        getCategoryById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                category: action.payload,
            };
        },

        updateCategoryById: (state, action) => {
            console.log(action.payload)
            return {
                ...state,
                categories: {
                    ...state.categories,
                    Collection: state.categories.Collection.map(item => item._id == action.payload._id ? action.payload : item)
                },
            };
        },

        deleteCategoryById: (state, action) => {
            return {
                ...state,
                categories: {
                    ...state.categories,
                    Collection: state.categories.Collection.filter(item => item._id !== action.payload)
                },
            };
        }

    }
})


export const { getCategories, addCategories , getCategoryById , updateCategoryById, deleteCategoryById} = categorySlice.actions
export default categorySlice.reducer