
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../common/BreadCrums";
import { getBrandsListingThunk } from "../../features/OtherApi/ApiThunk";
import NoImgFound from "./../../assets/No-image-found.jpg"

const BrandsListing = () => {
    const dispatch = useDispatch();
    document.title = "Brands Listing";
    const [brandsListing, setBrandsListing] = useState([]);


    useEffect(() => {
        dispatch(getBrandsListingThunk()).unwrap()
            .then((res) => {
                setBrandsListing(res);
            })
    }, [])

    return (
        <>

            <Breadcrumbs title="Brands Listing For Last 7 Days" />

            <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3 row-cols-xl-3">
                {brandsListing?.Collection?.length ? brandsListing?.Collection.map((item, index) => {
                    return (
                        <>
                            <div className="col">
                                <div className="card ">
                                    <div className="card-body d-flex justify-content-around align-items-center">
                                        <div>
                                            <img src={item.image ? `${process.env.REACT_APP_ASSET_URL}/${item.image}` : NoImgFound} className="card-img-top m-auto" alt="..." style={{ 'maxWidth': '120px', 'height': 'auto' }} />
                                        </div>
                                        <div>
                                            <h5 className="card-title text-uppercase">{item.name} </h5>
                                            <p className="card-title d-flex">({item.productCount}) Amount of Listings <box-icon name='trending-up'></box-icon></p>

                                            <Link to={`brands/${item._id}/products/?trending=true`} className="btn btn-primary"> View Products</Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }) : ''}

            </div>
        </>
    );
}

export default BrandsListing;